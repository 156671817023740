import { Button, Card, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import * as XLSX from 'xlsx'
import { SKU, ForecastHistory, DemandHistory } from './DemandAndForecastInterfaces'
import { addDataPointToSKU, downloadToCSV, resetSKU } from '../../utils/DemandAndForecastHelpers'

interface SKUDevUploadPanelProps {
  uploadedData: Array<SKU>,
  setUploadedData: Function,
  selectedSKUIndex: number,
  setSelectedSKUIndex: Function,
  selectSalesOrForecast: string,
  setSelectSalesOrForecast: Function
}

function SKUDevUploadPanel({ uploadedData, setUploadedData, selectedSKUIndex, setSelectedSKUIndex, selectSalesOrForecast, setSelectSalesOrForecast }: SKUDevUploadPanelProps) {
  if (selectedSKUIndex && selectedSKUIndex >= uploadedData.length) {
    setSelectedSKUIndex(-1)
  }

  return (
    <Card>
      <Row className='gy-4 gx-5 p-1'>
        <Col className='d-grid' xs={3}>
          <input
            className='csv-input'
            type='file'
            name='file'
            placeholder={undefined}
            onChange={e => uploadCSV(e, setUploadedData)}
          />
        </Col>
        <Col className='d-grid' xs={3}>
          <Button onClick={e => addRow(uploadedData, setUploadedData)}>Add row to end</Button>
        </Col>
        <Col className='d-grid' xs={3}>
          <Button onClick={e => deleteRandomRow(uploadedData, setUploadedData, selectedSKUIndex, setSelectedSKUIndex)}>Remove random row</Button>
        </Col>
        <Col className='d-grid' xs={3}>
          <Button onClick={e => downloadToCSV(uploadedData)}>Download</Button>
        </Col>

        <Col className='d-grid' xs={3}>
          <Form.Select
            value={selectedSKUIndex}
            onChange={e => setSelectedSKUIndex(parseInt(e.target.value as string))}
          >
            <option key={-1} value={-1}>{uploadedData.length > 0 ? 'Select a SKU' : ''}</option>
            {uploadedData.map((o, i) => {
              return (
                <option key={o.skuCode + ' | ' + o.warehouseCode} value={i}>{o.skuName + ' - (' + o.skuCode + ' | ' + o.warehouseCode + ')'}</option>
              )
            })}
          </Form.Select>
        </Col>
        <Col className='d-grid' xs={3}>
          <Button disabled={selectedSKUIndex < 0} onClick={e => addDataPointToSKU(uploadedData, setUploadedData, selectedSKUIndex)}>Add data point to selected SKU</Button>
        </Col>
        <Col className='d-grid' xs={3}>
          <Button disabled={selectedSKUIndex < 0} onClick={e => resetSKU(uploadedData, setUploadedData, selectedSKUIndex)}>Reset SKU values</Button>
        </Col>
        <Col className='d-grid' xs={3}>
          <Form>
            <div>
              <Form.Check label='Edit Sales Values' type='radio' id='checkbox-edit-sales' onClick={() => setSelectSalesOrForecast(selectSalesOrForecast === 'Sales' ? 'Forecast' : 'Sales')} checked={selectSalesOrForecast === 'Sales' ? true : false} />
              <Form.Check label='Edit Forecast Values' type='radio' id='checkbox-edit-forecast' onClick={() => setSelectSalesOrForecast(selectSalesOrForecast === 'Sales' ? 'Forecast' : 'Sales')} checked={selectSalesOrForecast === 'Forecast' ? true : false} />
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

async function uploadCSV(e: any, setUploadedData: Function) {
  const file = e.target.files[0];
  const xlsxFile = await file.arrayBuffer();
  const uploadedData: Array<any> = []

  const workbook = XLSX.read(xlsxFile);
  workbook.SheetNames.forEach(function (sheetName: string) {
    var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1, raw: false, dateNF: 'dd-mm-yyyy' })
    var json_object = JSON.stringify(XL_row_object)
    uploadedData.push(JSON.parse(json_object).slice(1))
  })

  const formattedData = uploadedData[0].map((o: Array<string>, i: number) => ({
    skuCode: parseInt(o[0]),
    warehouseCode: o[1],
    skuName: o[2],
    unitsOfMeasure: o[3],
    sdSupplyError: parseInt(o[4]),
    sdStrippingThreshold: parseInt(o[5]),
    leadTimeDays: parseInt(o[6]),
    replenishmentReviewFrequencyDays: parseInt(o[7]),
    averageReplenishmentUnits: parseInt(o[8]),
    availabilityTarget: parseFloat(o[9]),
    demandHistory: uploadedData[1].filter((d: Array<string>) => d[0] === o[0] && d[1] === o[1]).map((d: Array<string>) => ({
      skuCode: parseInt(d[0]),
      warehouseCode: d[1],
      date: new Date(d[2]),
      rawDemandQuantity: parseInt(d[3]),
      overriddenDemandQuantity: parseInt(d[4]),
      demandIsKnockedOut: d[5].toUpperCase() === 'TRUE'
    } as DemandHistory)),
    forecastHistory: uploadedData[2].filter((d: Array<any>) => d[0] === o[0] && d[1] === o[1]).map((d: Array<any>) => ({
      skuCode: parseInt(d[0]),
      warehouseCode: d[1],
      date: new Date(d[2]),
      rawForecastQuantity: parseInt(d[3]),
      overriddenForecastQuantity: parseInt(d[4]),
      forecastIsKnockedOut: d[5].toUpperCase() === 'TRUE'
    } as ForecastHistory)),
  } as SKU)) as Array<SKU>

  const cleansedData = formattedData.map(o => cleanSKUHistories(o))
  setUploadedData(cleansedData)
};

function cleanSKUHistories(sku: SKU) {
  const dates = sku.demandHistory.map(o => o.date.getTime()).concat(sku.forecastHistory.map(o => o.date.getTime()))
  const earliestDate = Math.min.apply(null, dates)
  const latestDate = Math.max.apply(null, dates)
  const trackerDate = new Date(earliestDate)
  let index = 0

  while (trackerDate.getTime() <= latestDate) {
    if (!sku.demandHistory.find(o => o.date.getTime() === trackerDate.getTime())) {
      sku.demandHistory.splice(index, 0, {
        skuCode: sku.skuCode,
        warehouseCode: sku.warehouseCode,
        date: new Date(trackerDate),
        rawDemandQuantity: 0,
        overriddenDemandQuantity: 0,
        demandIsKnockedOut: false
      } as DemandHistory)
    }

    if (!sku.forecastHistory.find(o => o.date.getTime() === trackerDate.getTime())) {
      sku.forecastHistory.splice(index, 0, {
        skuCode: sku.skuCode,
        warehouseCode: sku.warehouseCode,
        date: new Date(trackerDate),
        rawForecastQuantity: 0,
        overriddenForecastQuantity: 0,
        forecastIsKnockedOut: false
      } as ForecastHistory)
    }

    index += 1

    trackerDate.setDate(trackerDate.getDate() + 1)
  }

  return sku
}

function addRow(uploadedData: Array<SKU>, setUploadedData: Function) {
  const newUploadedData = uploadedData.map((o) => o)
  const randomPointsCount = Math.floor(Math.random() * 5) + 1
  const demandHistory = []
  const forecastHistory = []
  const skuCode = newUploadedData.length + 1
  const warehouseCode = "NE-" + Math.floor(Math.random() * (10 - 1 + 1) + 1)
  const demandQuantity = Math.floor(Math.random() * (700 - 1 + 1) + 1)
  const forecastQuantity = Math.floor(Math.random() * (700 - 1 + 1) + 1)

  const trackerDate = new Date()
  for (var i = 0; i < randomPointsCount; i++) {
    demandHistory.push({ skuCode, warehouseCode, date: new Date(trackerDate.toISOString().split('T')[0]), rawDemandQuantity: demandQuantity, overriddenDemandQuantity: demandQuantity, demandIsKnockedOut: true })
    forecastHistory.push({ skuCode, warehouseCode, date: new Date(trackerDate.toISOString().split('T')[0]), rawForecastQuantity: forecastQuantity, overriddenForecastQuantity: forecastQuantity, forecastIsKnockedOut: true })

    trackerDate.setDate(trackerDate.getDate() + 1)
  }

  const newRecord: SKU = {
    skuCode,
    warehouseCode,
    skuName: "SKU " + skuCode,
    unitsOfMeasure: "g",
    sdStrippingThreshold: 3,
    sdSupplyError: 100,
    leadTimeDays: 20,
    replenishmentReviewFrequencyDays: 7,
    averageReplenishmentUnits: 800,
    availabilityTarget: 0.985,
    demandHistory,
    forecastHistory
  }

  newUploadedData.push(newRecord)
  setUploadedData(newUploadedData)
}

function deleteRandomRow(uploadedData: Array<SKU>, setUploadedData: Function, selectedSKUIndex: number, setSelectedSKUIndex: Function) {
  if (uploadedData && uploadedData.length > 0) {
    const randomIndex = Math.floor(Math.random() * uploadedData.length)
    const newUploadedData = uploadedData.filter((o, i) => i !== randomIndex)

    setUploadedData(newUploadedData)

    if (randomIndex === selectedSKUIndex && newUploadedData.length > 0) {
      if (newUploadedData.length > 0) {
        setSelectedSKUIndex(0)
      } else {
        setSelectedSKUIndex(-1)
      }
    } else if (randomIndex < selectedSKUIndex) {
      setSelectedSKUIndex(selectedSKUIndex - 1)
    }
  }
}

export default SKUDevUploadPanel;
