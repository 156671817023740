import * as d3 from 'd3'
import { useRef, useEffect } from 'react'
import styles from '../../styles/chart.module.scss'

interface FVAProps {
  xValue: number
  yValue: number
  lockScale: boolean
}

export default function FVAChart({ lockScale, xValue, yValue }: FVAProps) {
  const width: number = 225
  const height: number = 225
  const margin: number = 50

  const ref: any = useRef()

  const drawChart = (data: FVAProps) => {
    const svg = d3.select(ref.current)
      .attr('class', styles.canvas)
      .style('height', height + 'px')
      .style('width', width + 'px')
      .attr('preserveAspectRatio', 'xMinYMin')
      .attr('viewBox', `${-margin} -15 ${width + (1.5 * margin)} ${height + (1 * margin)}`)
     
    svg.selectAll('rect,rect.placeholder-bars,circle,path,svg, .axis,defs').remove()

    const selection = svg.selectAll('rect')

    const min: number = 0
    const max: number = !data.lockScale && (data.yValue > 100 || data.xValue > 100) ? data.yValue > data.xValue ? data.yValue : data.xValue : 100

    const xScale: any = d3.scaleLinear()
      .domain([0, data.lockScale ? max : max * 1.2])
      .range([0, (width)])

    const yScale: any = d3.scaleLinear()
      .domain([min, data.lockScale ? max : max * 1.2])
      .range([((height)), 0])

    selection.enter().append('g')
      .attr('class', 'grid')

    selection.enter().append('g')
      .attr('id', 'yAxis')
      .call(yScale)

    const background = svg.append('svg')
      .attr('class', 'background')
      .style('opacity', '0.5')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)        
    background
      .append('polyline')
        .attr('points', `0 ${height}, 203 0, ${width} 0, ${width} 22`)
        .style('fill', '#f0ad4e')
    background
      .append('polyline')
        .attr('points', `0 ${height}, ${width} 22, ${width}, ${height}`)
        .style('fill', '#5cb85c')            
    background.append('polyline')
        .attr('points', `0 ${height}, 0 0, 203 0`)
        .style('fill', '#d9534f')

    const yAXIS: any = d3.axisLeft(yScale)

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .attr('class', 'axis')
      .call(d3.axisBottom(xScale))
      .style('font-size', '12px')
      .style('color', '#b3b3b3')
      .call(g => g.append("text")
        .attr("x", width / 2)
        .attr("y", 40)
        .attr("fill", "#b3b3b3")
        .style('font-size', '14px')
        .attr("text-anchor", "middle")
        .text("Demand COV (%)"))

    svg.append('g')
      .attr('transform', 'translate(0,0)')
      .attr('class', 'axis')
      .call(d3.axisLeft(yScale))
      .style('font-size', '12px')
      .style('color', '#b3b3b3')
      .call(g => g.append("text")
        .attr('transform', 'rotate(270, 0, 0)')
        .attr("x", -height / 2)
        .attr("y", -35)
        .attr("fill", "#b3b3b3")
        .style('font-size', '14px')
        .attr("border", "1px solid #333333")
        .attr("text-anchor", "middle")
        .text("Forecast Error COV (%)"))

    svg.select('#yAxis')
      .attr('class', styles.yaxis)
      .transition()
      .call(yAXIS)
      
    svg.selectAll('.dot')
      .data([data])
      .enter()
      .append('circle')
      .style('z-index', '-1')
      .style('fill', 'white')
      .style('stroke', function(data) {
          if ((Math.abs(data.xValue - data.yValue) / data.xValue) <= 0.1)
          {
            return '#cd8011'
          }
          else if(data.xValue > data.yValue)
          {
            return '#3b873b'
          }
          else
          {
            return '#aa2925'
          }
      })
      .style('stroke-width', '4px')
      .attr('cx', xScale(data.xValue.toString()))
      .attr('cy', yScale(data.yValue))
      .attr('r', 6)
  }

  useEffect(() => {
    if (ref.current && ref.current.clientWidth) {
      drawChart({ lockScale, xValue, yValue })
    }
    // eslint-disable-next-line
  }, [lockScale, xValue, yValue])

  return (
    <svg ref={ref} />
  )
}
