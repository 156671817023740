import { useState } from 'react'
import StockServiceTradeOffChart from '../features/StockServiceTradeOff'
import StockServiceTradeOffOptions from '../features/StockServiceTradeOff/StockServiceTradeOffOptions'
import { Card, Col, Container, Row } from 'react-bootstrap'
import AlertsDisplay from '../features/AlertsDisplay'

export interface IStockServiceTradeOffChartProp {
  label: number,
  costOfStock: number,
  costOfLostSales: number,
  costOfObsolescence: number,
  totalCost: number,

}

export interface IStockServiceTradeOffChartProps {
  result: IStockServiceTradeOffChartProp[],
  alertProcess: any
}

function StockOptimisation() {
  const [stockServiceTradeOffChartData, setStockServiceTradeOffChartData] = useState<IStockServiceTradeOffChartProps>({ result: [], alertProcess: null })

  return (
    <Container>
      <Row>

        <Col xs={12} md={5} lg={5}>
          <Card className="mb-3">
            <Card.Body>
              <StockServiceTradeOffOptions stockServiceTradeOffChartData={stockServiceTradeOffChartData} setStockServiceTradeOffChartData={setStockServiceTradeOffChartData} />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={7} lg={7}>
          <Card className="mb-3">
            <Card.Body>
              <StockServiceTradeOffChart result={stockServiceTradeOffChartData.result} alertProcess={stockServiceTradeOffChartData.alertProcess} />
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <AlertsDisplay alertProcess={stockServiceTradeOffChartData?.alertProcess} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default StockOptimisation
