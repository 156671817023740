import React, { useState } from 'react'
import FVAChart from "../features/FVA/FVA";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { useGetForecastErrorAnalysisStepsQuery } from '../services/functions'
import ForecastBiasChart from '../features/ForecastBiasChart/ForecastBiasChart';
import ValueDisplayBox from '../features/DemandAndForecast/ValueDisplayBox';
import SKUCalculationStepsTable from '../features/DemandAndForecast/SKUCalculationStepsTable';
import { SKU, ForecastErrorAnalysisStepsReturn } from '../features/DemandAndForecast/DemandAndForecastInterfaces'
import SKUDataTable from '../features/DemandAndForecast/SKUDataTable';
import AlertsDisplay from '../features/AlertsDisplay';
import DemandAndForecastChart from '../features/DemandAndForecast/DemandAndForecastChart';
import SKUUploadPanel from '../features/DemandAndForecast/SKUUploadPanel';
import { addDataPointToSKU, getForecastBiasColour, getFVAColour, resetSKU } from '../utils/DemandAndForecastHelpers'

function DemandAndForecastForecastAnalysis() {
  const [uploadedData, setUploadedData] = useState<Array<SKU>>([])
  const [selectedSKUIndex, setSelectedSKUIndex] = useState<number>(-1)
  const [selectSalesOrForecast, setSelectSalesOrForecast] = useState("Forecast");

  if (selectedSKUIndex && selectedSKUIndex >= uploadedData.length) {
    setSelectedSKUIndex(-1)
  }

  const selectedSKUObject = selectedSKUIndex >= 0 ? uploadedData[selectedSKUIndex] : undefined
  const forecastAnalysisInput = {
    smoothingPeriods: 1,
    sdSupplyError: selectedSKUObject?.sdSupplyError,
    leadTimeDays: selectedSKUObject?.leadTimeDays,
    replenishmentReviewFrequencyDays: selectedSKUObject?.replenishmentReviewFrequencyDays,
    averageReplenishmentUnits: selectedSKUObject?.averageReplenishmentUnits,
    availabilityTarget: selectedSKUObject?.availabilityTarget,
    rawDemandHistoryData: selectedSKUObject?.demandHistory.map(o => o.demandIsKnockedOut ? null : o.overriddenDemandQuantity),
    rawForecastHistoryData: selectedSKUObject?.forecastHistory.map(o => o.forecastIsKnockedOut ? null : o.overriddenForecastQuantity)
  }
  const hasNoDemand = forecastAnalysisInput.rawDemandHistoryData?.every(o => o === null || o === 0)
  const forecastErrorAnalysisSteps = useGetForecastErrorAnalysisStepsQuery(forecastAnalysisInput, { skip: hasNoDemand }).data as ForecastErrorAnalysisStepsReturn

  const forecastErrorCOV = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.forecastErrorVariability.cov ?? 0) * 100
  const demandVariabilityCOV = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.demandVariability.cov ?? 0) * 100
  const forecastValueAdd = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.forecastValueAdd ?? 0) * 100
  const forecastBias = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.forecastBias ?? 0) * 100
  const forecastAccuracy = undefined //TODO: Assign this value.

  return (
    <Container>
      <SKUUploadPanel uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} setSelectedSKUIndex={setSelectedSKUIndex} />

      <Card className='mt-2 p-1 pr-0'>
        {hasNoDemand ?
          (
            <Row>
              <Col xs={12} style={{backgroundColor: '#FF0000', color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px', textAlign: 'center'}}>
                Demand totals 0, calculations can not be made.
              </Col>
            </Row>
          ) : (<></>)
        }
        <Row>
          <Col xs={12}>
            <SKUDataTable uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} />
          </Col>
        </Row>
        <Row>
          <Col xs={9}>
            <DemandAndForecastChart height={200} width={600} uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} forecastErrorAnalysisSteps={forecastErrorAnalysisSteps} selectSalesOrForecast={selectSalesOrForecast} />
          </Col>
          <Col xs={3}>
            <Row className='mt-4' style={{ margin: 0 }}>
              <Button disabled={selectedSKUIndex < 0} onClick={e => addDataPointToSKU(uploadedData, setUploadedData, selectedSKUIndex)}>Add data point to SKU</Button>
            </Row>
            <Row className='mt-4'>
              <Form.Check label='Edit Sales Values' type='radio' id='checkbox-edit-sales' onClick={() => setSelectSalesOrForecast(selectSalesOrForecast === 'Sales' ? 'Forecast' : 'Sales')} checked={selectSalesOrForecast === 'Sales' ? true : false} />
              <Form.Check label='Edit Forecast Values' type='radio' id='checkbox-edit-forecast' onClick={() => setSelectSalesOrForecast(selectSalesOrForecast === 'Sales' ? 'Forecast' : 'Sales')} checked={selectSalesOrForecast === 'Forecast' ? true : false} />
            </Row>
            <Row className='mt-4' style={{ margin: 0 }}>
              <Button disabled={selectedSKUIndex < 0} onClick={e => resetSKU(uploadedData, setUploadedData, selectedSKUIndex)}>Reset SKU values</Button>
            </Row>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col xs={12}>
            <SKUCalculationStepsTable selectedSKU={selectedSKUObject} data={forecastErrorAnalysisSteps} />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs={2}/>
          <Col xs={2}>
            <FVAChart lockScale xValue={demandVariabilityCOV} yValue={forecastErrorCOV} />
          </Col>
          <Col xs={6}>
            <Row className='mt-2 ml-2' style={{ display: 'flex', justifyContent: 'space-around' }}>
              <ValueDisplayBox backgroundColor={getForecastBiasColour(forecastBias)} displayValue={forecastBias.toFixed(0) + '%'} valueDescription={'Bias'} />
              <ValueDisplayBox backgroundColor={getFVAColour(demandVariabilityCOV, forecastErrorCOV)} displayValue={forecastErrorCOV.toFixed(0) + '%'} valueDescription={'Forecast Error COV'} />
              <ValueDisplayBox backgroundColor='#337AB7' displayValue={demandVariabilityCOV.toFixed(0) + '%'} valueDescription={'Demand COV'} />
              <ValueDisplayBox backgroundColor={getFVAColour(demandVariabilityCOV, forecastErrorCOV)} displayValue={forecastValueAdd.toFixed(0) + '%'} valueDescription={'FVA'} />
              <ValueDisplayBox backgroundColor='#ECA9A7' displayValue={'NULL'} valueDescription={'Forecast Accuracy'} />
            </Row>
            <Row>
              <ForecastBiasChart forecastBiasPercentage={forecastBias} height={200} width={600} />
            </Row>
          </Col>
        </Row>
        <Row className='m-2'>
          <Col xs={12}>
            <AlertsDisplay alertProcess={forecastErrorAnalysisSteps?.alertProcess} />
          </Col>
        </Row>
      </Card>
    </Container>
  )
}

export default DemandAndForecastForecastAnalysis;