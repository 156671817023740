import { useAuth0 } from "@auth0/auth0-react";
import { NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, Outlet } from "react-router-dom";
import "../../styles/bootstrap/custom.scss";
import "../../styles/bootstrap-icons.scss";

function Layout() {
  return (
    <>
        <Header />
        <main role="main" className="flex-shrink-0 pb-3">
          <Outlet />
        </main>
        <Footer />
    </>
  );
}

function PersonCircle() {
  return (
    <>
      <span className="bi bi-person-circle" role="img" aria-label="account"></span>
    </>
  );
}

function Header() {
  const { user, logout } = useAuth0();
  return (
    <Navbar bg="light" expand="lg" className="mb-3">
      <Container>
        <Link to="" style={{ textDecoration: "none" }}>
          <Navbar.Brand>Sequoia Demos</Navbar.Brand>
        </Link>
        {/* <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav>
            <Nav.Link as={Link} to='bootstrap'>Misc</Nav.Link>
            <Nav.Link as={Link} to='bootstrap/forms'>Forms</Nav.Link>
            <Nav.Link as={Link} to='bootstrap/VariabilityDemo'>Variability Demo</Nav.Link>
            <Nav.Link as={Link} to='bootstrap/Version1'>Version1</Nav.Link>
            <Nav.Link as={Link} to='bootstrap/VariabilityDemoAlt'>Variability Demo alt</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
        <NavDropdown title={<PersonCircle />} align="end">
          <NavDropdown.Item disabled>
            Signed in as: {user?.name ?? ""}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log out
          </NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
  );
}

function Footer() {
  return (
    <footer className="footer mt-auto py-3 bg-light">
      <Container className="text-center">
        <span className="text-secondary">&copy; The Sequoia Partnership Ltd</span>
      </Container>
    </footer>
  );
}

export default Layout;
