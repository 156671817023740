import { useState } from "react";
import { Container, Col, Form } from "react-bootstrap";

export default function StockServiceCurveChart() {
    const [plotDaysOrUnits, setPlotDaysOrUnits] = useState<any>('Units');
    const [showStockTarget, setShowStockTarget] = useState<boolean>(false)

    return (

        <Container>
            <fieldset>
                <legend>Chart Options</legend>

                <Form>

                    <fieldset className="row mb-2">
                        <legend
                            className="col-form-label col-sm-2 pt-0"
                        >
                            Y-Axis Units
                        </legend>
                        <Col sm="6">
                            <Form.Check
                                inline
                                label='Units'
                                type='radio'
                                radioGroup='chart-axis-group'
                                id='radio-chart-axis-units'
                                onChange={() => setPlotDaysOrUnits(plotDaysOrUnits === 'Units' ? 'Days' : 'Units')}
                                checked={plotDaysOrUnits === 'Units' ? true : false}
                            />
                            <Form.Check
                                inline
                                label='Days'
                                type='radio'
                                radioGroup='chart-axis-group'
                                id='radio-chart-axis-days'
                                onChange={() => setPlotDaysOrUnits(plotDaysOrUnits === 'Units' ? 'Days' : 'Units')}
                                checked={plotDaysOrUnits === 'Days' ? true : false}
                            />
                        </Col>
                    </fieldset>

                    <fieldset className="row mb-2">
                        <legend
                            className="col-form-label col-sm-2 pt-0"
                        >
                            Show Target
                        </legend>
                        <Col sm="6">
                            <Form.Check
                                inline
                                type='switch'
                                className=''
                                label='show'
                                id='switch-show-target'
                                onChange={() => setShowStockTarget(!showStockTarget)} checked={showStockTarget}
                            />
                        </Col>
                    </fieldset>
                </Form>

            </fieldset>

            <Container className="mt-5">
                <b>To-do list</b>
                <ul>
                    <li>Add stock-service chart curve</li>
                    <li>Trigger chart to refresh if any inputs are changed on the left</li>
                    <li>Make Y-Axis units depend on radio button option above, which when changed should trigger the chart to refresh</li>
                    <li>Decide whether switch to show selected target on chart should be in this panel, or on left with inputs</li>
                    <li>Only show enum names in Average Replenishment UoM option</li>
                </ul>
            </Container>

        </Container>
    )
}