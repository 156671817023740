import * as d3 from "d3";
import { nest } from "d3-collection";
import { useRef, useEffect, useState } from "react";
import styles from "../../styles/chart.module.scss";
import { AxisType, Chart, LegendPosition, Series, SeriesValues, UpliftDemoAdditionalProperties } from "../../utils/ChartConfigurations";

interface IUpliftDemo {
  props: Series<UpliftDemoAdditionalProperties>[];
  width: number;
  height: number;
  margin: any;
  selectSalesOrForecast: string;
  setSalesValues: any;
  setPerfectForecastValues: any;
  showSdStrippingThreshold: any;
  sdStrippingThresholds: any;
}

export default function UpliftDemoChart({
  props,
  width,
  height,
  margin,
  selectSalesOrForecast,
  setSalesValues,
  setPerfectForecastValues,
  showSdStrippingThreshold,
  sdStrippingThresholds
}: IUpliftDemo) {
  const data = props;
  const lastStrippingThreshold = sdStrippingThresholds ? sdStrippingThresholds[sdStrippingThresholds.length - 1] : []
  const chartStyle: Chart = {
    ...upliftDemoDefault,
    height: height ? height : upliftDemoDefault.height,
    width: width ? width : upliftDemoDefault.width
  }

  const [savedCoords, setSavedCoords] = useState<Array<Array<number>>>([[0, 0],[0, 0]])
  const ref: any = useRef();

  const drawChart = (data: Series<UpliftDemoAdditionalProperties>[], chartStyle: Chart) => {
    const allValues: SeriesValues<UpliftDemoAdditionalProperties>[] = data.map(o => o.values).flat(1)

    const sumstat = nest()
      .key((d: any) => d.name)
      .entries(allValues);

    const svg = d3
      .select(ref.current)
      // .attr('class', styles.canvas)
      .attr("preserveAspectRatio", "xMinYMin")
      .attr(
        "viewBox",
        `-40 -5 ${chartStyle.width + margin.left + margin.right} ${chartStyle.height + margin.top + margin.bottom
        }`
      );

    svg
      .selectAll(
        "rect,rect.placeholder-bars,.line-datapoint,line.placeholder-line,#legend,#watermark,circle,.brush,line"
      )
      .remove();

    const selection = svg.selectAll("rect").data(sumstat);

    const y: any = d3
      .scaleLinear()
      .domain([0, 3000])
      .range([chartStyle.height - margin.top + margin.bottom, 0]);

    const x: any = d3
      .scaleBand()
      .domain(allValues.map(o => o.xValue))
      .range([0, chartStyle.width - (margin.left + margin.right)]);

    selection
      .enter()
      .append("g")
      .attr("class", "grid");

    selection
      .enter()
      .append("g")
      .attr("id", "yAxis")
      .attr('color', '#b3b3b3')
      .call(y);

    const yAXIS: any = d3
      .axisLeft(y)
      .ticks(8);

    svg
      .select("#yAxis")
      .attr("class", styles.yaxis)
      .transition()
      .call(yAXIS);

    // svg.append('text')
    //   .attr('id', 'watermark')
    //   .attr('class', styles.watermark)
    //   .attr('x', margin.left + margin.right)
    //   .attr('y', margin.bottom + margin.top)
    //   .attr('dy', '1em')
    //   .text('The Sequoia')

    // svg.append('text')
    //   .attr('id', 'watermark')
    //   .attr('class', styles.watermark)
    //   .attr('x', margin.left + margin.right)
    //   .attr('y', margin.bottom + margin.top)
    //   .attr('dy', '2.5em')
    //   .text('Partnership')

    svg
      .selectAll(".lineTest")
      .remove();

    const valueline = (d: any) => d3.line()
      .x((v: any) => x(v.xValue))
      .y((v: any) => y(v.yValue))
      .defined((v: any) => v.yValue !== null && v.additionalProperties.showDataPoint)
      (d.values);
        
    const color: any = d3
      .scaleOrdinal()
      .domain(data.map(o => o.name))
      .range(data.map(o => o.colour));

    let line = svg
      .selectAll(".lineTest")
      .data(sumstat)
      .enter()
      .append("path")
      .attr("class", "lineTest")
      .attr("d", (d: any) => valueline(d))
      .attr("id", (d: any) => d.key + "-line")
      .attr("stroke", (d: any) => color(d.key))
      .attr("stroke-width", 1.5)
      .attr("fill", "none");

    const legendHolder = svg
      .append("g")
      .attr("transform", "translate(" + -chartStyle.width + "," + -margin.top + ")");

    const legend = legendHolder
      .selectAll(".legendHolder")
      .data(sumstat.slice())
      .enter()
      .append("g")
      .attr("width", 36);
    legend
      .append("rect")
      .attr("x", (d: any, i: number) => chartStyle.width + 150 * i)
      .attr("y", chartStyle.height + margin.bottom + margin.top)
      .attr("width", 15)
      .attr("height", 3)
      .attr("fill", (d: any, i: number) => color(d.key));
    legend
      .append("text")
      .attr("x", (d, i) => chartStyle.width + 150 * i + 20)
      .attr("y", chartStyle.height + margin.bottom + margin.top)
      .attr("dy", ".5em")
      .text((d) => d.key)
      .attr("fill", "#333333")
      .attr("id", "legend")
      .attr("class", styles.legend);
    
    //Sd Stripping Threshold
    if(allValues.length > 0 && showSdStrippingThreshold && lastStrippingThreshold.upperFilter >= 0) {
      if(lastStrippingThreshold.lowerFilter >= 0) {
        svg.append("line")
          .style("stroke", "black")
          .attr("x1", x(allValues[0].xValue))
          .attr("y1", lastStrippingThreshold.lowerFilter)
          .attr("x2", x(allValues[allValues.length - 1].xValue))
          .attr("y2", lastStrippingThreshold.lowerFilter)
      }

      svg.append("line")
        .style("stroke", "black")
        .style("stroke-width", "2px")
        .attr("x1", x(allValues[0].xValue))
        .attr("y1", y(lastStrippingThreshold.upperFilter))
        .attr("x2", x(allValues[allValues.length - 1].xValue))
        .attr("y2", y(lastStrippingThreshold.upperFilter))
    }    

    /**
     * DRAGGABLE FUNCTIONALITY - START
     * Functionality request - enable draggable datapoints. This functionality wasn't requested for a particular chart or to be a part of a new component.
     * This functionality was attached to this component for proof of concept (uncomment to see in action).
     */
    const brush = d3
      .brush()
      .extent([
        [0, 0],
        [chartStyle.width, chartStyle.height - margin.top + margin.bottom],
      ])

    svg
      .append("g")
      .attr("class", "brush")
      .call(brush);

    const chartConfig = {
      x_axis: ["xValue"],
      y_axis: ["yValue"],
      groupBy: ["name"],
    };
    const xAxisAttr = chartConfig.x_axis[0];
    const yAxisAttr = chartConfig.y_axis[0];
    const coordsObj: any = {};

    function moveCoords(event: any) {
      const coords: any = event.selection;
      if (!coords) return;
      const xDeltaNew: any = coords[0][0] - coords[1][0];
      const yDeltaNew: any = coords[0][1] - coords[1][1];

      if (coordsObj.xDelta === xDeltaNew && coordsObj.yDelta === yDeltaNew) {
        d3.selectAll(".selected").attr("cy", (d: any) => {
          if (!d.originalY) {
            d.originalY = y(d[yAxisAttr]);
          }

          const currentCoord = coordsObj.yPos - coords[0][1];

          const yCoordinate =
            d.originalY - currentCoord < 0 ? 1 : d.originalY - currentCoord;

          d[yAxisAttr] = y.invert(yCoordinate);

          line.attr("d", (d: any) => valueline(d));

          circles
            .attr("cx", function (d) {
              return x(d.xValue);
            })
            .attr("cy", function (d) {
              return y(d.yValue);
            });

          return yCoordinate;
        });
      }
    }

    function selectCircles(event: any) {
      const coords: any = event.selection;

      if (!coords) {
        setSavedCoords([[0, 0],[0, 0]])
        circles.classed("selected", false)
        return;
      }
      
      coordsObj.xDelta = coords[0][0] - coords[1][0];
      coordsObj.yDelta = coords[0][1] - coords[1][1];
      coordsObj.yPos = coords[0][1];
      const coordsChanged = coords[0][0] !== savedCoords[0][0] || coords[1][0] !== savedCoords[1][0] || coords[0][1] !== savedCoords[0][1] || coords[1][1] !== savedCoords[1][1]
      if(coordsChanged) {
        setSavedCoords([[coords[0][0],coords[0][1]],[coords[1][0],coords[1][1]]])
      }

      if (event.type === "end" && coordsChanged) {
        if (d3.selectAll(".selected").data().length > 0) {
          const updatedData = allValues.filter(
            (a) => a.name === selectSalesOrForecast
          );

          if (selectSalesOrForecast === "Forecast") {
            setPerfectForecastValues(updatedData);
          } else {
            setSalesValues(updatedData);
          }
        }
      }

      circles.classed("selected", (d: any, xAxis: any) => {
        if (event.type === "end") {
          delete d.originalY;
        }

        // toggle here for sales/forecast
        if (d.name !== selectSalesOrForecast) {
          return;
        }

        return isBrushed(coords, x(d[xAxisAttr]), y(d[yAxisAttr]));
      });
    }

    function isBrushed(coords: any, cx: any, cy: any): any {
      const x0 = coords[0][0];
      const x1 = coords[1][0];
      const y0 = coords[0][1];
      const y1 = coords[1][1];

      return x0 <= cx && cx <= x1 && y0 <= cy && cy <= y1;
    }

    const circles = svg
      .selectAll(".dot")
      .data(allValues.filter(o => o.yValue !== null && o.additionalProperties.showDataPoint)).enter()
      .append("circle")
      .style("z-index", "-1")
      .style("fill", "#ffffff")
      .style("stroke", (d: any, i: number) => color(d.name))
      .style("stroke-width", 2)
      .attr("cx", function (d) {
        return x(d.xValue);
      })
      .attr("cy", function (d) {
        return y(d.yValue);
      })
      .attr("r", 4)
      .on("click", function(event, dataPointObject) {
        const actualType = dataPointObject.name.slice(-2) === 'KO' ? dataPointObject.name.slice(0, dataPointObject.name.length - 2) : dataPointObject.name
        let updatedData = allValues.filter((a) => a.name === actualType);
        updatedData[dataPointObject.additionalProperties.dataIndex].additionalProperties.isKnockedOut = !dataPointObject.additionalProperties.isKnockedOut

        if (actualType === "Forecast") {
          setPerfectForecastValues(updatedData, true);
        } else if(actualType === "Sales") {
          setSalesValues(updatedData, true);
        }
      });

    //Sd Stripped Data Points
    svg.selectAll(".dot").data(allValues.map((o, i) => {
      return { xValue: o.xValue, stripped: o.additionalProperties.isSdStripped, index: i, yValue: o.additionalProperties.preSdStrippingValue}
    }).filter(o => o.stripped)).enter()
    .append("circle")
    .style("z-index", "-1")
    .style("stroke", "#FF0000")
    .style("border-radius", "50%")
    .attr("cx", function (d: any) {
      return x(d.xValue);
    })
    .attr("cy", function (d) {
      return y(d.yValue);
    })
    .attr("r", 3)
    
    /*
     * Functions are added to the brush after circles are defined as the functions memorise the state of circles when stored in the brush.
     * Drawing the circles before the brush makes it impossible to click on them as the brush window will eat the click events.
     * */
    brush
      .on("end", (d) => selectCircles(d))
      .on("brush", (d) => moveCoords(d))

    //@ts-ignore
    d3.select(".brush").call(brush.move, savedCoords)

    /*
     * DRAGGABLE FUNCTIONALITY - END
     * */
  };

  useEffect(() => {
    if (ref.current && ref.current.clientWidth) {
      drawChart(data, chartStyle);
    }
    //data change can be realised and dealt with here
    // eslint-disable-next-line
  }, [data, selectSalesOrForecast]);

  return <svg ref={ref} />;
}

const upliftDemoDefault: Chart = {
  id: '0',
  title: 'Uplift Demo',
  xAxis: {
    title: 'xAxis Title',
    axisType: AxisType.Date
  },
  yAxis: {
    title: 'yAxis Title'
  },
  legend: {
    isVisible: true,
    position: LegendPosition.Top
  },
  height: 1000,
  width: 450,
  series: []
}
