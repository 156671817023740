import * as d3 from "d3";
import { useRef, useEffect } from "react";

interface ForecastErrorCOVOptions {
  forecastErrorData: number[] | [];
  demandData: number[] | [];
  overlayForecastError: boolean;
}

function ForecastErrorCOVChart({
  forecastErrorData = [],
  demandData = [],
  overlayForecastError,
}: ForecastErrorCOVOptions) {
  //const margin: any = { top: 10, right: 10, bottom: 20, left: 10 }
  const width: number = 430;
  const height: number = 430;

  const ref: any = useRef();

  const drawChart = (
    data: number[] = [],
    demandData: number[] = [],
    overlayForecastError: boolean
  ) => {
    const svg = d3
      .select(ref.current)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "-40 -15 500 500")
      .classed("svg-content", true);
    //.attr('preserveAspectRatio', 'xMinYMin')
    //.attr('viewBox', `-40 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)

    svg
      .selectAll("rect,rect.placeholder-bars,.line-datapoint,line.placeholder-line,#legend,#watermark,circle,line,.line,svg,g,path")
      .remove();

    var x = d3
      .scaleLinear()
      .domain([0, 100])
      .range([0, width]);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")");
    //.call(d3.axisBottom(x));

    // Add Y axis
    var y = d3
      .scaleLinear()
      .domain([100, 0])
      .range([height, 0]);

    svg
      .append("g")
      .call(d3.axisLeft(y).tickSize(0).tickValues([]));

    //add line
    // var line = d3.line()
    //     .x(() => x(0))
    //     .y(() => y(50))

    svg
      .append("line")
      .attr("x1", x(0))
      .attr("x2", width)
      .attr("y1", y(50))
      .attr("y2", y(50))
      .style("stroke", "white")
      .style("stroke-width", 1)

    // Add dots
    if (overlayForecastError && forecastErrorData.length !== 0) {
      svg
        .append("g")
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d: any) {
          return x(d);
        })
        .attr("cy", function (d: any, i: any) {
          return y(i);
        })
        .attr("r", 1.5)
        .style("fill", "#C00000");
    }

    if (demandData.length !== 0) {
      svg
        .append("g")
        .selectAll("dot")
        .data(demandData)
        .enter()
        .append("circle")
        .attr("cx", function (d: any) {
          return x(d);
        })
        .attr("cy", function (d: any, i: any) {
          return y(i);
        })
        .attr("r", 1.5)
        .style("fill", "#287e7e");
    }
  };

  useEffect(() => {
    if (ref.current && ref.current.clientWidth) {
      drawChart(forecastErrorData, demandData, overlayForecastError);
    }
    // eslint-disable-next-line
  }, [forecastErrorData, demandData, overlayForecastError]);

  return <svg ref={ref} />;
}

export default ForecastErrorCOVChart;
