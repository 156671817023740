import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function DemoSelect() {
  const cards = [
    { demoLink: 'demand-variability', demoName: 'Demand Variability', demoDescription: 'Simple demo of the different components of demand.' },
    { demoLink: 'forecast-error', demoName: 'Forecast Error', demoDescription: 'Interactive demonstration of Forecast Error and Demand Variability and their COV.' },
    //{ demoLink: 'stock-service-curve', demoName: 'Stock-Service Curve', demoDescription: 'Shows the relationship between stock and service level for a continuous sales SKU.' },
    { demoLink: 'stock-optimisation', demoName: 'Stock Optimisation', demoDescription: 'Trade off to find the economic service level.' },
    { demoLink: 'demand-and-forecast', demoName: 'Demand & Forecast', demoDescription: 'This is an area for Demand & Forecast analysis. Import data from an .xlsx file.' },
    { demoLink: 'demand-and-forecast-safety-stock', demoName: 'Demand & Forecast (Safety Stock)', demoDescription: 'Demand & Forecast analysis demo with preloaded data.' },
    { demoLink: 'demand-and-forecast-forecast-analysis', demoName: 'Demand & Forecast (Forecast Analysis)', demoDescription: 'Alternative version of the Demand & Forecast demo.' }
  ]
  return (
    <Container>
      <Row>
        {cards.map((card) => (
          <Col key={card.demoLink} xs={12} sm={6} md={4} className='mb-3'>
            <Card className="h-100">
              {/* <Card.Img variant="top" src="..." alt="..." /> */}
              <Card.Body className='d-flex flex-column'>
                <Card.Title>
                  {card.demoName}
                </Card.Title>
                <Card.Text>
                  {card.demoDescription}
                </Card.Text>
                <Link
                  to={`/demo/${card.demoLink}`}
                  className='btn btn-primary align-self-baseline mt-auto'
                >
                  View
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default DemoSelect
