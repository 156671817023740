import { Route, Routes } from 'react-router-dom'
import './App.css'
import ForecastError from './pages/ForecastError'
import ProtectedComponent from './utils/ProtectedRoute'
import Layout from './components/layout'
import DemoSelect from './pages/DemoSelect'
import DemandVariability from './pages/DemandVariability'
import StockServiceCurve from './pages/StockServiceCurve'
import StockOptimisation from './pages/StockOptimisation'
import DemandAndForecast from './pages/DemandAndForecast'
import { TokenContext } from './utils/TokenContext'
import { useDispatch } from 'react-redux'
import { setAccessToken } from './app/tokenSlice'
import useFetchAccessToken from './utils/useFetchAccessToken'
import DemandAndForecastSafetyStock from './pages/DemandAndForecastSafetyStock'
import DemandAndForecastForecastAnalysis from './pages/DemandAndForecastForecastAnalysis'

function App() {
  const dispatch = useDispatch()

  const accessToken = useFetchAccessToken()

  if (accessToken !== null) {
    dispatch(setAccessToken(accessToken))
  }

  return (
    <TokenContext.Provider value={accessToken}>
      <Routes>
        <Route element={<ProtectedComponent component={Layout} />}>
          <Route path='' element={<ProtectedComponent component={DemoSelect} />} />
          <Route path='demo'>
            <Route path='demand-variability' element={<ProtectedComponent component={DemandVariability} />} />
            <Route path='forecast-error' element={<ProtectedComponent component={ForecastError} />} />
            <Route path='stock-service-curve' element={<ProtectedComponent component={StockServiceCurve} />} />
            <Route path='stock-optimisation' element={<ProtectedComponent component={StockOptimisation} />} />
            <Route path='demand-and-forecast' element={<ProtectedComponent component={DemandAndForecast} />} />
            <Route path='demand-and-forecast-safety-stock' element={<ProtectedComponent component={DemandAndForecastSafetyStock} />} />
            <Route path='demand-and-forecast-forecast-analysis' element={<ProtectedComponent component={DemandAndForecastForecastAnalysis} />} />
          </Route>
        </Route>
      </Routes>
    </TokenContext.Provider>
  )
}

export default App
