import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './app/store'
import history from './utils/history'

const onRedirectCallback = (appState: any) => {
  // Use the router's history module to replace the url
  history.replace(appState && appState?.returnTo
    ? appState?.returnTo
    : window.location.pathname)
  // a problem with the versions of react router and history stated here in this github issue require the following line https://github.com/auth0/auth0-react/issues/61
  //history.go(0)
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain='dev-15636rhu.eu.auth0.com'
      clientId='z1iCe4pbHgcTifRopXqYrZT4CSkexCW8'
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience='https://dev-sequoia-functions-api.sequoiaontarget.com'
    >
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
