import * as d3 from 'd3'
import { useRef, useEffect } from 'react'
import styles from '../../styles/chart.module.scss'
import { IStockServiceTradeOffChartProps } from '../../pages/StockOptimisation'

function StockServiceTradeOffChart(props: IStockServiceTradeOffChartProps) {
  const data = props.result === undefined ? [] : props.result
  const allNumbers = props.result === undefined ? [] : data.map(o => o.costOfStock).concat(data.map(o => o.costOfLostSales)).concat(data.map(o => o.costOfObsolescence)).concat(data.map(o => o.totalCost))

  let formattedData: { name: string, values: { label: string, value: number }[] }[] = [
    { name: 'Cost of Stock', values: data.map(o => ({ label: o.label.toString(), value: o.costOfStock })) },
    { name: 'Cost of Lost Sales', values: data.map(o => ({ label: o.label.toString(), value: o.costOfLostSales })) },
    { name: 'Cost of Obsolescence', values: data.map(o => ({ label: o.label.toString(), value: o.costOfObsolescence })) },
    { name: 'Total Cost', values: data.map(o => ({ label: o.label.toString(), value: o.totalCost })) }
  ]

  const margin: any = { top: 20, right: 10, bottom: 10, left: 10 }
  const width: number = 1000
  const height: number = 600

  const ref: any = useRef()

  const drawChart = (data: any) => {
    const svg = d3
      .select(ref.current)
      .attr('class', styles.canvas)
      .attr('preserveAspectRatio', 'xMinYMin')
      .attr('viewBox', `-50 -5 ${width + margin.left + margin.right + 10} ${height + margin.top + margin.bottom}`)
    svg
      .selectAll('g,rect,rect.placeholder-bars,.line-datapoint,line.placeholder-line,#legend,#watermark,circle,.lineTest,.line,path')
      .remove()

    const selection = svg
      .selectAll('.line')
      .data(formattedData)

    const min: number = 0 //Math.min(...allNumbers) * 0.8
    const max: number = Math.max(...allNumbers) * 1.2
    const domain = data[0].values[0] === undefined ? [] : [data[0].values[0].label, data[0].values[data[0].values.length - 1].label]

    const x: any = d3
      .scaleLinear()
      .domain(domain)
      .range([0, (width - (margin.left + margin.right))])

    const y: any = d3
      .scaleLinear()
      .domain([min, max])
      .range([height, 0])

    selection
      .enter()
      .append('g')
      .attr('class', 'grid')

    selection
      .enter()
      .append('g')
      .attr('id', 'xAxis')
      .attr('color', '#b3b3b3')
      .call(x)

    selection
      .enter()
      .append('g')
      .attr('id', 'yAxis')
      .attr('color', '#b3b3b3')
      .call(y)

    const xAxis: any = d3
      .axisBottom(x)
      .ticks(8, "~%")

    svg
      .select('#xAxis')
      .attr('class', styles.xaxis)
      .attr('transform', 'translate(' + 0 + ',' + height + ')')
      .transition()
      .call(xAxis)

    const yAxis: any = d3
      .axisLeft(y)
      .ticks(10)

    svg
      .select('#yAxis')
      .attr('class', styles.yaxis)
      .transition()
      .call(yAxis)

    const res = formattedData.map(function (d) { return d.name })
    const colour = d3
      .scaleOrdinal()
      .domain(res)
      .range(['#0000ff', '#00ff00', '#000000', '#ff0000'])

    const line = d3.line()
      .x(function (d: any) { return x(d[0]) })
      .y(function (d: any) { return y(d[1]) })

    svg
      .selectAll('.line')
      .data(formattedData)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', function (d): any { return colour(d.name) })
      .attr('stroke-width', 2)
      .attr('d', function (d) { return line(d.values.map((o: any) => [o.label, o.value])) })

    const legendHolder = svg
      .append("g")
      .attr("transform", "translate(" + (-width + 50) + "," + -margin.top + ")");

    const legend = legendHolder
      .selectAll(".legendHolder")
      .data(res)
      .enter()
      .append("g")
      .attr("width", 36);
    legend
      .append("rect")
      .attr("x", (d: any, i: number) => width + 200 * i)
      .attr("y", margin.top)
      .attr("width", 15)
      .attr("height", 3)
      .attr("fill", function (d: string): any { return colour(d) });
    legend
      .append("text")
      .attr("x", (d, i) => width + 200 * i + 20)
      .attr("y", margin.top)
      .attr("dy", ".5em")
      .text((d) => d)
      .attr("fill", "#333333")
      .attr("id", "legend")
      .attr("class", styles.legend);

  }

  useEffect(() => {
    if (ref.current && ref.current.clientWidth && data !== undefined) {
      drawChart(formattedData)
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <svg ref={ref} />
  )
}

export default StockServiceTradeOffChart