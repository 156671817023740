import React, { useState, useCallback} from 'react'
import { Button } from "react-bootstrap";
import { DataGrid, GridAlignment } from '@mui/x-data-grid'
import moment from 'moment'
import { SKU } from './DemandAndForecastInterfaces'

interface SKUDataTableProps {
  uploadedData: Array<SKU>
  setUploadedData: Function
  selectedSKUIndex: number
}

interface TableRow {
  index: number,
  skuCode: number,
  warehouseCode: string,
  date: Date,
  rawDemandQuantity: number,
  overriddenDemandQuantity: number,
  demandIsKnockedOut: boolean,
  rawForecastQuantity: number,
  overriddenForecastQuantity: number,
  forecastIsKnockedOut: boolean
}

export default function SKUDataTable ({ uploadedData, setUploadedData, selectedSKUIndex }: SKUDataTableProps) {
  const selectedSKU = selectedSKUIndex >= 0 ? uploadedData[selectedSKUIndex] : undefined
  
  const [tablePageSize, setTablePageSize] = useState(10)
  const tableData = getTableData(selectedSKU, uploadedData, setUploadedData, selectedSKUIndex)
  
  const rowUpdate = useCallback(async (newRow: TableRow, oldRow: TableRow) => {
    const rowToUpdate = uploadedData[selectedSKUIndex]
    if (rowToUpdate === undefined) { return newRow }

    rowToUpdate.demandHistory[newRow.index] = { skuCode: newRow.skuCode, warehouseCode: newRow.warehouseCode, date: newRow.date, rawDemandQuantity: newRow.rawDemandQuantity, overriddenDemandQuantity: Math.round(newRow.overriddenDemandQuantity), demandIsKnockedOut: newRow.demandIsKnockedOut }
    rowToUpdate.forecastHistory[newRow.index] = { skuCode: newRow.skuCode, warehouseCode: newRow.warehouseCode, date: newRow.date, rawForecastQuantity: newRow.rawForecastQuantity, overriddenForecastQuantity: Math.round(newRow.overriddenForecastQuantity), forecastIsKnockedOut: newRow.forecastIsKnockedOut }

    const newUploadedData = uploadedData.map((o: SKU, i) => i === selectedSKUIndex ? rowToUpdate : o)
    setUploadedData(newUploadedData)

    return newRow
  }, [uploadedData, setUploadedData, selectedSKUIndex])

  return (
    <DataGrid
      rows={tableData.data}
      columns={tableData.columns}
      autoHeight
      pageSize={tablePageSize}
      onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
      rowsPerPageOptions={[10, 25, 100]}
      columnBuffer={6}
      columnThreshold={6}
      getRowId={(row) => row.index}
      density='compact'
      experimentalFeatures={{ newEditingApi: true }}
      editMode='row'
      disableSelectionOnClick
      processRowUpdate={rowUpdate}
      headerHeight={80}
    />
  )
}

function getTableData(selectedSKU: SKU | undefined, uploadedData: Array<SKU>, setUploadedData: Function, selectedSKUIndex: number) {
  if (!selectedSKU) { return { columns: [], data: [] } }

  const data = []

  for (let i = 0; i < selectedSKU.demandHistory.length; i++) {
    data.push({
      index: i,
      skuCode: selectedSKU.skuCode,
      warehouseCode: selectedSKU.warehouseCode,
      date: selectedSKU.demandHistory[i].date,
      rawDemandQuantity: selectedSKU.demandHistory[i].rawDemandQuantity,
      overriddenDemandQuantity: selectedSKU.demandHistory[i].overriddenDemandQuantity,
      demandIsKnockedOut: selectedSKU.demandHistory[i].demandIsKnockedOut,
      rawForecastQuantity: selectedSKU.forecastHistory[i].rawForecastQuantity,
      overriddenForecastQuantity: selectedSKU.forecastHistory[i].overriddenForecastQuantity,
      forecastIsKnockedOut: selectedSKU.forecastHistory[i].forecastIsKnockedOut
    } as TableRow)
  }

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.8,
      align: 'left' as GridAlignment,
      editable: false,
      type: 'date',
      valueFormatter: (params: any) => moment(params.value).format("DD MMM YYYY").toUpperCase()
    },
    {
      field: 'rawDemandQuantity',
      headerName: 'Raw Demand Quantity',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: false,
      type: 'number'
    },
    {
      field: 'overriddenDemandQuantity',
      headerName: 'Overridden Demand Quantity',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: true,
      type: 'number'
    },
    {
      field: 'demandIsKnockedOut',
      headerName: 'Demand KO?',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: true,
      type: 'boolean'
    },
    {
      field: 'rawForecastQuantity',
      headerName: 'Raw Forecast Quantity',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: false,
      type: 'number'
    },
    {
      field: 'overriddenForecastQuantity',
      headerName: 'Overridden Forecast Quantity',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: true,
      type: 'number'
    },
    {
      field: 'forecastIsKnockedOut',
      headerName: 'Forecast KO?',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: true,
      type: 'boolean'
    },
    {
      field: 'index',
      headerName: 'Reset Row',
      flex: 0.5,
      align: 'left' as GridAlignment,
      editable: false,
      renderCell: (params: any) => <Button onClick={e => resetRow(uploadedData, setUploadedData, selectedSKUIndex, params.value)}>Reset</Button>
    }
  ]

  return { columns, data }
}

function resetRow(uploadedData: Array<SKU>, setUploadedData: Function, selectedSKUIndex: number, arrayIndex: number) {
  const rowToUpdate = uploadedData[selectedSKUIndex]
  if (rowToUpdate === undefined) { return }

  rowToUpdate.demandHistory[arrayIndex].overriddenDemandQuantity = rowToUpdate.demandHistory[arrayIndex].rawDemandQuantity
  rowToUpdate.forecastHistory[arrayIndex].overriddenForecastQuantity = rowToUpdate.forecastHistory[arrayIndex].rawForecastQuantity

  const newUploadedData = uploadedData.map((o: SKU, i) => i === rowToUpdate.skuCode ? rowToUpdate : o)
  setUploadedData(newUploadedData)
}
