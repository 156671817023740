import { Card, Col, Container, Row } from "react-bootstrap"
import StockServiceCurveChart from "../features/StockServiceCurve/StockServiceCurveChart"
import StockServiceCurveInputs from "../features/StockServiceCurve/StockServiceCurveInputs"

function StockServiceCurve() {
    return (
        <Container>
          <Row>
    
            <Col lg={3} className='mb-3'>
              <Card className='h-100'>
                <Card.Body>
                  <StockServiceCurveInputs />
                </Card.Body>
              </Card>
            </Col>
    
            <Col lg={9} className='mb-3'>
            <Card className='h-100'>
                <Card.Body>
                  <StockServiceCurveChart />                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
    )
}

export default StockServiceCurve