import { Series, SeriesValues, UpliftDemoAdditionalProperties } from "../../utils/ChartConfigurations"
import UpliftDemoChart from "../UpliftDemo/UpliftDemo"
import { SKU, ForecastHistory, DemandHistory, ForecastErrorAnalysisStepsReturn } from "./DemandAndForecastInterfaces"

interface DemandAndForecastChartProps {
  uploadedData: Array<SKU>
  setUploadedData: Function
  selectedSKUIndex: number
  forecastErrorAnalysisSteps: ForecastErrorAnalysisStepsReturn
  selectSalesOrForecast: string
  height: number
  width: number
}

export default function DemandAndForecastChart ({ uploadedData, setUploadedData, selectedSKUIndex, forecastErrorAnalysisSteps, selectSalesOrForecast, height = 900, width = 600 }: DemandAndForecastChartProps) {
  const selectedSKU = selectedSKUIndex >= 0 ? uploadedData[selectedSKUIndex] : undefined
  const chartData = getChartData(selectedSKU, forecastErrorAnalysisSteps)

  function setForecastValues(newValues: Array<SeriesValues<UpliftDemoAdditionalProperties>>, isKOChange: boolean = false) {
    const rowToUpdate = uploadedData[selectedSKUIndex]
    if (rowToUpdate === undefined) { return }

    rowToUpdate.forecastHistory = newValues.map((o, i) => ({
      skuCode: rowToUpdate.skuCode,
      warehouseCode: rowToUpdate.warehouseCode,
      date: new Date(o.xValue),
      rawForecastQuantity: rowToUpdate.forecastHistory[i].rawForecastQuantity,
      overriddenForecastQuantity: isKOChange ? rowToUpdate.forecastHistory[i].overriddenForecastQuantity : Math.round(o.yValue),
      forecastIsKnockedOut: o.additionalProperties.isKnockedOut
    } as ForecastHistory))

    const newUploadedData = uploadedData.map((o, i) => i === selectedSKUIndex ? rowToUpdate : o)
    setUploadedData(newUploadedData)
  }
  
  function setSalesValues(newValues: Array<SeriesValues<UpliftDemoAdditionalProperties>>, isKOChange: boolean = false) {
    const rowToUpdate = uploadedData[selectedSKUIndex]
    if (rowToUpdate === undefined) { return }

    rowToUpdate.demandHistory = newValues.map((o, i) => ({
      skuCode: rowToUpdate.skuCode,
      warehouseCode: rowToUpdate.warehouseCode,
      date: new Date(o.xValue),
      rawDemandQuantity: rowToUpdate.demandHistory[i].rawDemandQuantity,
      overriddenDemandQuantity: isKOChange ? rowToUpdate.demandHistory[i].overriddenDemandQuantity : Math.round(o.yValue),
      demandIsKnockedOut: o.additionalProperties.isKnockedOut
    } as DemandHistory))

    const newUploadedData = uploadedData.map((o: SKU, i) => i === selectedSKUIndex ? rowToUpdate : o)
    setUploadedData(newUploadedData)
  }

  return (
    <UpliftDemoChart
      props={chartData}
      width={width}
      height={height}
      margin={{ top: 20, right: 10, bottom: 10, left: 20 }}
      selectSalesOrForecast={selectSalesOrForecast}
      setPerfectForecastValues={setForecastValues}
      setSalesValues={setSalesValues}
      showSdStrippingThreshold={forecastErrorAnalysisSteps?.isSdStrippingNecessary}
      sdStrippingThresholds={forecastErrorAnalysisSteps?.sdStrippingThresholds}
    />
  )
}

function getChartData(selectedSKU: SKU | undefined, data: any ) {
  const chartSeries: Series<UpliftDemoAdditionalProperties>[] = [
    {
      name: 'Sales',
      colour: '#287e7e',
      values: [],
      isVisible: true,
      isVisibleInLegend: true
    }, {
      name: 'SalesKO',
      colour: '#287e7e55',
      values: [],
      isVisible: true,
      isVisibleInLegend: true
    }, {
      name: 'Forecast',
      colour: '#e9841d',
      values: [],
      isVisible: true,
      isVisibleInLegend: true
    }, {
      name: 'ForecastKO',
      colour: '#e9841d55',
      values: [],
      isVisible: true,
      isVisibleInLegend: true
    }
  ]

  if (selectedSKU && data && data.forecastErrorAnalysis.negativeStrippedDemandHistory && data.forecastErrorAnalysis.negativeStrippedForecastHistory &&
    selectedSKU.demandHistory.length === data.forecastErrorAnalysis.negativeStrippedDemandHistory.length &&
    selectedSKU.forecastHistory.length === data.forecastErrorAnalysis.negativeStrippedForecastHistory.length) {
    const lastThreshold = data.isSdStrippingNecessary && data.sdStrippingThresholds ? data.sdStrippingThresholds[data.sdStrippingThresholds.length - 1] : null
    const demandDataToIterateOver = data.isSdStrippingNecessary ? data.sdStrippedDemandAnalysis.historyData : data.forecastErrorAnalysis.negativeStrippedDemandHistory

    demandDataToIterateOver.forEach((o: number, i: number) => {
      const relatedDemandHistory = selectedSKU.demandHistory[i]
      const relatedNegativeStrippedValue = data.forecastErrorAnalysis.negativeStrippedDemandHistory[i]

      chartSeries[0].values.push({
        name: 'Sales',
        xValue: relatedDemandHistory.date.toString(),
        yValue: o,
        additionalProperties: {
          dataIndex: i,
          preSdStrippingValue: relatedNegativeStrippedValue,
          isSdStripped: data.isSdStrippingNecessary && (relatedNegativeStrippedValue < lastThreshold.lowerFilter || relatedNegativeStrippedValue > lastThreshold.upperFilter),
          isKnockedOut: relatedDemandHistory.demandIsKnockedOut,
          showDataPoint: !relatedDemandHistory.demandIsKnockedOut
        }
      })
      chartSeries[1].values.push({
        name: 'SalesKO',
        xValue: relatedDemandHistory.date.toString(),
        yValue: relatedDemandHistory.overriddenDemandQuantity,
        additionalProperties: {
          dataIndex: i,
          preSdStrippingValue: relatedDemandHistory.overriddenDemandQuantity,
          isSdStripped: data.isSdStrippingNecessary && (relatedNegativeStrippedValue < lastThreshold.lowerFilter || relatedNegativeStrippedValue > lastThreshold.upperFilter),
          isKnockedOut: relatedDemandHistory.demandIsKnockedOut,
          showDataPoint: relatedDemandHistory.demandIsKnockedOut
        }
      })
    })

    data.forecastErrorAnalysis.negativeStrippedForecastHistory.forEach((o: number, i: number) => {
      const relatedForecastHistory = selectedSKU.forecastHistory[i]
      
      chartSeries[2].values.push({
        name: 'Forecast',
        xValue: relatedForecastHistory.date.toString(),
        yValue: o,
        additionalProperties: {
          dataIndex: i,
          preSdStrippingValue: relatedForecastHistory.overriddenForecastQuantity,
          isSdStripped: false,
          isKnockedOut: relatedForecastHistory.forecastIsKnockedOut,
          showDataPoint: !relatedForecastHistory.forecastIsKnockedOut
        }
      })
      chartSeries[3].values.push({
        name: 'ForecastKO',
        xValue: relatedForecastHistory.date.toString(),
        yValue: relatedForecastHistory.overriddenForecastQuantity,
        additionalProperties: {
          dataIndex: i,
          preSdStrippingValue: relatedForecastHistory.overriddenForecastQuantity,
          isSdStripped: false,
          isKnockedOut: relatedForecastHistory.forecastIsKnockedOut,
          showDataPoint: relatedForecastHistory.forecastIsKnockedOut
        }
      })
    })
  }

  return chartSeries
}
