import _ from "lodash";
import { useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";

enum UnitsOption {
    Days = 1, 
    Units = 2,
};

export default function StockServiceCurveInputs() {

    const [averageDemandValue, setAverageDemandValue] = useState<number>(1000);
    const [demandCovValue, setDemandCovValue] = useState<number>(100);
    const [leadtimeValue, setLeadtimeValue] = useState<number>(7);
    const [replenishmentReviewFrequencyValue, setReplenishmentReviewFrequencyValue] = useState<number>(7);
    const [supplyErrorCovValue, setSupplyErrorCovValue] = useState<number>(0);
    const [averageReplenishmentValue, setAverageReplenishmentValue] = useState<number>(2000);
    const [averageReplenishmentUnitsOfMeasureValue, setAverageReplenishmentUnitOfMeasureValue] = useState<UnitsOption>(UnitsOption.Units);
    const [serviceLevelTargetValue, setServiceLevelTargetValue] = useState<number>(98.5);
    const [showStockTarget, setShowStockTarget] = useState<boolean>(false)

    return (
        <Container>

            <fieldset>
                <legend>SKU Properties</legend>

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label 
                            htmlFor='average-demand'
                        >
                            Average Demand
                        </Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                id='average-demand'
                                placeholder='Average Demand'
                                value={averageDemandValue}
                                onChange={(e): void => setAverageDemandValue(parseInt(e.target.value)) }
                            />
                            <InputGroup.Text>units</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label
                            htmlFor='demand-cov'
                        >
                            Demand Variability (COV)
                        </Form.Label>
                        <InputGroup size="sm">
                            <Form.Control 
                                id='demand-cov'
                                placeholder='Noise'
                                type='number'
                                step={10} min={0} max={200}
                                value={demandCovValue}
                                onChange={(e): void => setDemandCovValue(parseInt(e.target.value))}
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label
                            htmlFor='leadtime'
                        >
                            Leadtime
                        </Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                id='leadtime'
                                placeholder='Leadtime'
                                type='number'
                                step={1} min={0} max={730}
                                value={leadtimeValue}
                                onChange={(e): void => setLeadtimeValue(parseInt(e.target.value))}
                            />
                            <InputGroup.Text>days</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label
                            htmlFor='replenishment-review-frequency'
                        >
                            Replenishment Review Frequency
                        </Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                id='replenishment-review-frequency'
                                placeholder='Replenishment Review Frequency'
                                type='number'
                                step={1} min={0} max={365}
                                value={replenishmentReviewFrequencyValue}
                                onChange={(e): void => setReplenishmentReviewFrequencyValue(parseInt(e.target.value))}
                            />
                            <InputGroup.Text>days</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label 
                            htmlFor='average-replenishment'
                        >
                            Average Replenishment
                        </Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                id='average-replenishment'
                                placeholder='Average Replenishment'
                                value={averageReplenishmentValue}
                                onChange={(e): void => setAverageReplenishmentValue(parseInt(e.target.value))}
                            />
                            <Form.Select
                                id='average-Replenishment-Units-Of-Measure'
                                value={averageReplenishmentUnitsOfMeasureValue}
                                onChange={(e): void => setAverageReplenishmentUnitOfMeasureValue(parseInt(e.target.value)) }
                                title='average replenishment units'
                            >
                                {_.map(UnitsOption, (value, key) => (
                                    <option value={key} key={key}>{value}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label
                            htmlFor='supply-error-cov'
                        >
                            Supply Error COV
                        </Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                id='supply-error-cov'
                                placeholder='Supply Error COV'
                                type='number'
                                step={10} min={0} max={100}
                                value={supplyErrorCovValue}
                                onChange={(e): void => setSupplyErrorCovValue(parseInt(e.target.value))}
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label
                            htmlFor='service-level-target'
                        >
                            Service Level Target
                        </Form.Label>
                        <Form.Check 
                            inline type='switch'
                            className='float-end me-0'
                            label='show'
                            id='checkbox-show-target'
                            onChange={() => setShowStockTarget(!showStockTarget)} checked={showStockTarget}
                        />
                        <InputGroup size="sm">
                            <Form.Control
                                id='service-level-target'
                                placeholder='Service Level Target'
                                type='number'
                                step={0.5} min={0} max={99.999}
                                value={serviceLevelTargetValue}
                                onChange={(e): void => setServiceLevelTargetValue(parseFloat(e.target.value))}
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                </Form>
            </fieldset>

        </Container>
    )
}