import { Card, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { SKU } from './DemandAndForecastInterfaces'

interface StockTargetPanelProps {
  uploadedData: Array<SKU>,
  setUploadedData: Function,
  selectedSKUIndex: number,
  stockTargetFormErrors: any,
  setStockTargetFormErrors: Function,
  columns: number
}

function StockTargetPanel({ uploadedData, setUploadedData, selectedSKUIndex, stockTargetFormErrors, setStockTargetFormErrors, columns }: StockTargetPanelProps) {
  const selectedSKUObject = uploadedData[selectedSKUIndex]
  const rowClassName = 'row-cols-' + columns

  function setField<T extends keyof SKU>(field: T, value: SKU[T]) {
    if (!selectedSKUObject) { return }
  
    selectedSKUObject[field] = value
    setUploadedData(uploadedData.map((o, i) => i === selectedSKUIndex ? selectedSKUObject : o))

    const { sdSupplyError, leadTimeDays, replenishmentReviewFrequencyDays, averageReplenishmentUnits, availabilityTarget, sdStrippingThreshold } = selectedSKUObject
    const newErrors: any = {}
    
    if(sdSupplyError < 0 || sdSupplyError > 2)
      newErrors.sdSupplyError = '0 <= x <= 200'
    if(leadTimeDays < 0)
      newErrors.leadTimeDays = '0 <= x'
    if(replenishmentReviewFrequencyDays < 0)
      newErrors.replenishmentReviewFrequencyDays = '0 <= x'
    if(averageReplenishmentUnits < 0)
      newErrors.averageReplenishmentUnits = '0 <= x'
    if(availabilityTarget < 0 || availabilityTarget > 2)
      newErrors.availabilityTarget = '0 <= x <= 200'
    if(sdStrippingThreshold < 0 || sdStrippingThreshold >= 5)
      newErrors.sdStrippingThreshold = '0 <= x <= 5'

    setStockTargetFormErrors(newErrors)
  }

  return (    
    <Card className='mt-2 p-1 pr-0'>
      <Row className={rowClassName + ' gy-5'}>
        <Col className='d-grid'>
          <Form.Label for='sdSupplyErrorInput'>Supply Error (%)</Form.Label>
          <Form.Control id='sdSupplyErrorInput' type='number' disabled={selectedSKUIndex < 0}
            value={selectedSKUObject ? selectedSKUObject.sdSupplyError * 100 : undefined}
            onChange={e => setField('sdSupplyError' as keyof SKU, parseFloat(e.target.value) / 100)}
            isInvalid={stockTargetFormErrors.sdSupplyError}
            />
          <Form.Control.Feedback type='invalid'>
            {stockTargetFormErrors.sdSupplyError}
          </Form.Control.Feedback>
        </Col>
        <Col className='d-grid'>
          <Form.Label for='leadTimeDaysInput'>Lead Time Days</Form.Label>
          <Form.Control id='leadTimeDaysInput' type='number' disabled={selectedSKUIndex < 0}
            value={selectedSKUObject ? selectedSKUObject.leadTimeDays : undefined}
            onChange={e => setField('leadTimeDays' as keyof SKU, parseInt(e.target.value))}
            isInvalid={stockTargetFormErrors.leadTimeDays}
            />
          <Form.Control.Feedback type='invalid'>
            {stockTargetFormErrors.leadTimeDays}
          </Form.Control.Feedback>
        </Col>
        <Col className='d-grid'>
          <Form.Label for='replenishmentReviewFrequencyDaysInput'>Replenishment Review Frequency Days</Form.Label>
          <Form.Control id='replenishmentReviewFrequencyDaysInput' type='number' disabled={selectedSKUIndex < 0}
            value={selectedSKUObject ? selectedSKUObject.replenishmentReviewFrequencyDays : undefined}
            onChange={e => setField('replenishmentReviewFrequencyDays' as keyof SKU, parseInt(e.target.value))}
            isInvalid={stockTargetFormErrors.replenishmentReviewFrequencyDays}
            />
          <Form.Control.Feedback type='invalid'>
            {stockTargetFormErrors.replenishmentReviewFrequencyDays}
          </Form.Control.Feedback>
        </Col>
        <Col className='d-grid'>
          <Form.Label for='averageReplenishmentUnitsInput'>Average Replenishment Units</Form.Label>
          <Form.Control id='averageReplenishmentUnitsInput' type='number' disabled={selectedSKUIndex < 0}
            value={selectedSKUObject ? selectedSKUObject.averageReplenishmentUnits : undefined}
            onChange={e => setField('averageReplenishmentUnits' as keyof SKU, parseInt(e.target.value))}
            isInvalid={stockTargetFormErrors.averageReplenishmentUnits}
            />
          <Form.Control.Feedback type='invalid'>
            {stockTargetFormErrors.averageReplenishmentUnits}
          </Form.Control.Feedback>
        </Col>
        <Col className='d-grid'>
          <Form.Label for='availabilityTargetInput'>Availability Target (%)</Form.Label>
          <Form.Control id='availabilityTargetInput' type='number' disabled={selectedSKUIndex < 0}
            value={selectedSKUObject ? selectedSKUObject.availabilityTarget * 100 : undefined}
            onChange={e => setField('availabilityTarget' as keyof SKU, parseFloat(e.target.value) / 100)}
            isInvalid={stockTargetFormErrors.availabilityTarget}
            />
          <Form.Control.Feedback type='invalid'>
            {stockTargetFormErrors.availabilityTarget}
          </Form.Control.Feedback>
        </Col>
        <Col className='d-grid'>
          <Form.Label for='sdStrippingThresholdInput'>Sd Stripping Threshold</Form.Label>
          <Form.Control id='sdStrippingThresholdInput' type='number' disabled={selectedSKUIndex < 0}
            value={selectedSKUObject ? selectedSKUObject.sdStrippingThreshold : undefined}
            onChange={e => setField('sdStrippingThreshold' as keyof SKU, parseInt(e.target.value))}
            isInvalid={stockTargetFormErrors.sdStrippingThreshold}
            />
          <Form.Control.Feedback type='invalid'>
            {stockTargetFormErrors.sdStrippingThreshold}
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Card>
  )
}

export default StockTargetPanel