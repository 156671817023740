import { useEffect, useMemo, useState } from 'react'
import { CalculateSeasonaility, CalculatePromo, CalculateTrend } from '../../utils/calculations'
import { useGetForecastErrorAnalysisQuery, useGetStandardDistributionQuery, useGetNoiseQuery } from '../../services/functions'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { Series, SeriesValues, UpliftDemoAdditionalProperties } from "../../utils/ChartConfigurations";

const perfectForecast = (arrayLength: number, averageDemandValue: number, includeSeasonaility: boolean, includeTrend: boolean, includePromo: boolean, seasonaility: number[], trend: number[], promo: number[]) => {
  return {
    name: 'Forecast',
    values: Array.apply(null, Array(arrayLength)).map((_, i): SeriesValues<UpliftDemoAdditionalProperties> => ({
      name: 'Forecast',
      xValue: i.toString(),
      yValue: (averageDemandValue +
        (includeSeasonaility ? seasonaility[i] : 0) +
        (includeTrend ? trend[i] : 0) +
        (includePromo ? promo[i] : 0)),
      additionalProperties: {
        dataIndex: i,
        preSdStrippingValue: (averageDemandValue +
          (includeSeasonaility ? seasonaility[i] : 0) +
          (includeTrend ? trend[i] : 0) +
          (includePromo ? promo[i] : 0)),
        isSdStripped: false,
        isKnockedOut: false,
        showDataPoint: true
      }
    })),
    colour: '#e9841d',
    isVisible: true,
    isVisibleInLegend: true
  }
}
const demand = (perfectForecast: SeriesValues<UpliftDemoAdditionalProperties>[], includeNoise: boolean, noise: number[]) => {
  return {
    name: 'Demand',
    values: [...perfectForecast].map((o, i) => ({
      ...o,
      name: 'Demand',
      yValue: o.yValue + (includeNoise ? noise[i] : 0),
      additionalProperties: {
        ...(o.additionalProperties),
        preSdStrippingValue: o.yValue + (includeNoise ? noise[i] : 0)
      }
    })),
    colour: '#287e7e',
    isVisible: true,
    isVisibleInLegend: true
  }
}

interface IForecastErrorOptions {
  setForecastChartData: any
  setUpliftDemoChartData: any
  setApiData: any
  setSelectSalesOrForecast: any
  selectSalesOrForecast: any
  perfectForecastValues: Series<UpliftDemoAdditionalProperties> | undefined
  salesValues: Series<UpliftDemoAdditionalProperties> | undefined
  setPerfectForecastValues: any
  setSalesValues: any
  setFeCOVData: any
  setDvCOVData: any
}

export default function ForecastErrorOptions({ setForecastChartData, setUpliftDemoChartData, setApiData, setSelectSalesOrForecast, selectSalesOrForecast, perfectForecastValues, salesValues: demandValues, setSalesValues, setPerfectForecastValues, setFeCOVData, setDvCOVData }: IForecastErrorOptions) {
  const [includeSeasonailityInDemand, setIncludeSeasonalityInDemand] = useState<boolean>(true)
  const [includeTrendInDemand, setIncludeTrendInDemand] = useState<boolean>(true)
  const [includePromoInDemand, setIncludePromoInDemand] = useState<boolean>(true)
  const [includeNoiseInDemand, setIncludeNoiseInDemand] = useState<boolean>(true)

  const [includeSeasonailityInForecast, setIncludeSeasonalityInForecast] = useState<boolean>(true)
  const [includeTrendInForecast, setIncludeTrendInForecast] = useState<boolean>(true)
  const [includePromoInForecast, setIncludePromoInForecast] = useState<boolean>(true)

  const arrayLength = 60
  const averageDemandValue = 1000
  const [covValue, setCovValue] = useState<number>(40)
  const [seasonalityValue, setSeasonalityValue] = useState<number>(500)
  const [trendValue, setTrendValue] = useState<number>(20)
  const [promoValue, setPromoValue] = useState<number>(1000)
  const [goButtonPressed, setGoButtonPressed] = useState<boolean>(false)

  const [noise, setNoise] = useState<number[]>([])

  const noiseInput = useMemo(() => {
    return {
      mean: averageDemandValue,
      cov: covValue / 100,
      arrayLength: arrayLength
    }
  }, [averageDemandValue, covValue, arrayLength])

  const { data: noiseData, isSuccess: noiseIsSuccess, refetch } = useGetNoiseQuery(noiseInput)

  useEffect(() => {
    if (goButtonPressed) {
      refetch()
      setGoButtonPressed(false)
    }
  }, [refetch, goButtonPressed, setGoButtonPressed])

  useEffect(() => {
    if (noiseIsSuccess) {
      setNoise(noiseData)
    }
  }, [noiseIsSuccess, setNoise, noiseData])

  const cycle: number[] = useMemo<number[]>(() => CalculateSeasonaility(seasonalityValue, arrayLength), [seasonalityValue, arrayLength, goButtonPressed])
  const trend: number[] = useMemo<number[]>(() => CalculateTrend(trendValue, averageDemandValue, arrayLength), [trendValue, averageDemandValue, arrayLength, goButtonPressed])
  const promo: number[] = useMemo<number[]>(() => CalculatePromo(promoValue, arrayLength), [promoValue, arrayLength, goButtonPressed])

  useEffect(() => {
    setPerfectForecastValues(perfectForecast(arrayLength, averageDemandValue, includeSeasonailityInForecast, includeTrendInForecast, includePromoInForecast, cycle, trend, promo))
  }, [arrayLength, averageDemandValue, includeSeasonailityInForecast, includeTrendInForecast, includePromoInForecast, cycle, trend, promo, setPerfectForecastValues])

  const perfectForecastValuesCopy = useMemo(() => perfectForecast(arrayLength, averageDemandValue, includeSeasonailityInDemand, includeTrendInDemand, includePromoInDemand, cycle, trend, promo), [arrayLength, averageDemandValue, includeSeasonailityInDemand, includeTrendInDemand, includePromoInDemand, cycle, trend, promo])

  useEffect(() => {
    if (noise.length !== 0) {
      setSalesValues(demand(perfectForecastValuesCopy.values, includeNoiseInDemand, noise))
    }
  }, [perfectForecastValuesCopy, includeNoiseInDemand, noise, setSalesValues])

  const forecastAnalysisInput = useMemo(() => {
    return {
      smoothingPeriods: 1,
      rawDemandHistoryData: demandValues?.values.map(a => a.yValue),
      rawForecastHistoryData: perfectForecastValues?.values.map(a => a.yValue)
    }
  }, [demandValues, perfectForecastValues])

  const [skip, setSkip] = useState(true)

  if (forecastAnalysisInput.rawForecastHistoryData?.length !== 0 && forecastAnalysisInput.rawDemandHistoryData?.length !== 0 && !isNaN(forecastAnalysisInput.rawDemandHistoryData?.[0]) && skip) {
    setSkip(false)
  }

  const { data, isSuccess } = useGetForecastErrorAnalysisQuery(forecastAnalysisInput, { skip })

  const avgDemandAndStdDevDv = {
    avgDemand: averageDemandValue,
    stdDev: data?.demandVariability?.sd
  }
  const [skip2, setSkip2] = useState(true)

  const { data: data2, isSuccess: isSuccess2 } = useGetStandardDistributionQuery(avgDemandAndStdDevDv, { skip: skip2 })

  useEffect(() => {
    if (isSuccess2) {
      setDvCOVData(data2)
    }
  }, [isSuccess2, setDvCOVData, data2])

  const avgDemandAndStdDevFe = {
    avgDemand: averageDemandValue,
    stdDev: data?.forecastErrorVariability?.sd
  }
  const [skip1, setSkip1] = useState(true)

  const { data: data1, isSuccess: isSuccess1 } = useGetStandardDistributionQuery(avgDemandAndStdDevFe, { skip: skip1 })

  useEffect(() => {
    if (isSuccess1) {
      setFeCOVData(data1)
    }
  }, [isSuccess1, setFeCOVData, data1])

  useEffect(() => {
    if (isSuccess) {
      setSkip1(false)
      setSkip2(false)
      setApiData(data)
    }
  }, [isSuccess, setApiData, data, setSkip1, setSkip2])

  useEffect(() => {
    if (perfectForecastValues && perfectForecastValues.values.length !== 0 && demandValues && demandValues.values.length !== 0) {
      const b = demandValues.values
      const result = perfectForecastValues?.values.map((o, i) => {
        return { type: 'Demand', dataIndex: i, label: i.toString(), value: b[i].yValue - o.yValue, preSdStrippingValue: b[i].yValue - o.yValue, isSdStripped: false, isKnockedOut: false, showDataPoint: true }
      })
      setForecastChartData(result)
    }
  }, [setForecastChartData, demandValues, perfectForecastValues])

  useEffect(() => {
    const salesValuesWithinAxes = {
      ...demandValues,
      values: demandValues?.values.map((a, i) => {
        const actualValue = a.yValue < 0 ? 0 : a.yValue > 3000 ? 3000 : a.yValue
        return { ...a, yValue: actualValue, additionalProperties: { ...(a.additionalProperties), preSdStrippingValue: actualValue }}
      })
    }
    
    if(salesValuesWithinAxes && salesValuesWithinAxes.values && perfectForecastValues) {
      setUpliftDemoChartData([salesValuesWithinAxes, perfectForecastValues])
    }
  }, [perfectForecastValues, demandValues, setUpliftDemoChartData])

  return (
    <Container>

      <fieldset>
        <legend>Demand Pattern</legend>

        <Form>
          <Form.Group
            as={Row}
            className="mb-2"
            controlId="averageDemand"
          >
            <Form.Label
              column="sm"
              sm={2} md={12} lg={2}
            >
              Average Demand
            </Form.Label>
            <Col
              xs={5} sm={4} md={5} lg={4}
            >
              <InputGroup size='sm'>
                <Form.Control
                  placeholder='Average Demand'
                  disabled value={averageDemandValue}
                />
                <InputGroup.Text>units</InputGroup.Text>
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-2"
            controlId="cov"
          >
            <Form.Label
              column="sm"
              sm={2} md={12} lg={2}
            >
              <abbr title="Coefficient of Variability">COV</abbr>
            </Form.Label>
            <Col
              xs={5} sm={4} md={5} lg={4}
            >
              <InputGroup size="sm">
                <Form.Control placeholder='COV' type='number' step={10} min={0} max={100} value={covValue} onChange={(e): void => setCovValue(parseInt(e.target.value))} />
                <InputGroup.Text>%</InputGroup.Text>
                <Button size='sm' variant='primary' onClick={() => setGoButtonPressed(!goButtonPressed)}>
                  <span className='bi bi-arrow-clockwise' role='img' aria-label='refresh'></span>
                </Button>
              </InputGroup>
            </Col>
            <Col
              xs={7} sm={6} md={7} lg={{ offset: 0, span: 6 }}
            >
              <Form.Check inline type='switch' className='pt-1' label='demand' id='include-noise-demand' checked={includeNoiseInDemand} onChange={() => setIncludeNoiseInDemand(!includeNoiseInDemand)} />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-2"
            controlId="cycle"
          >
            <Form.Label
              column="sm"
              sm={2} md={12} lg={2}
            >
              Seasonality
            </Form.Label>
            <Col
              xs={5} sm={4} md={5} lg={4}
            >
              <InputGroup size='sm'>
                <Form.Control placeholder='Seasonality' type='number' step={100} min={0} max={500} value={seasonalityValue} onChange={(e): void => setSeasonalityValue(parseInt(e.target.value))} />
                <InputGroup.Text>units</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col
              xs={7} sm={6} md={7} lg={{ offset: 0, span: 6 }}
            >
              <Form.Check inline type='switch' className='pt-1' label='demand' id='include-cycle-demand' checked={includeSeasonailityInDemand} onChange={() => setIncludeSeasonalityInDemand(!includeSeasonailityInDemand)} />
              <Form.Check inline type='switch' className='pt-1 me-0' label='forecast' id='include-cycle-forecast' checked={includeSeasonailityInForecast} onChange={() => setIncludeSeasonalityInForecast(!includeSeasonailityInForecast)} />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-2"
            controlId="trend"
          >
            <Form.Label
              column="sm"
              sm={2} md={12} lg={2}
            >
              Trend
            </Form.Label>
            <Col
              xs={5} sm={4} md={5} lg={4}
            >
              <InputGroup size="sm">
                <Form.Control placeholder='Trend' type='number' step={10} min={0} max={50} value={trendValue} onChange={(e): void => setTrendValue(parseInt(e.target.value))} />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col
              xs={7} sm={6} md={7} lg={{ offset: 0, span: 6 }}
            >
              <Form.Check inline type='switch' className='pt-1' label='demand' id='include-trend-demand' checked={includeTrendInDemand} onChange={() => setIncludeTrendInDemand(!includeTrendInDemand)} />
              <Form.Check inline type='switch' className='pt-1 me-0' label='forecast' id='include-trend-forecast' checked={includeTrendInForecast} onChange={() => setIncludeTrendInForecast(!includeTrendInForecast)} />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-2"
            controlId="promo"
          >
            <Form.Label
              column="sm"
              sm={2} md={12} lg={2}
            >
              Promo
            </Form.Label>
            <Col
              xs={5} sm={4} md={5} lg={4}
            >
              <InputGroup size='sm'>
                <Form.Control placeholder='Promo' type='number' step={100} min={0} max={1000} value={promoValue} onChange={(e): void => setPromoValue(parseInt(e.target.value))} />
                <InputGroup.Text>units</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col
              xs={7} sm={6} md={7} lg={{ offset: 0, span: 6 }}
            >
              <Form.Check inline type='switch' className='pt-1' label='demand' id='include-promo-demand' checked={includePromoInDemand} onChange={() => setIncludePromoInDemand(!includePromoInDemand)} />
              <Form.Check inline type='switch' className='pt-1 me-0' label='forecast' id='include-promo-forecast' checked={includePromoInForecast} onChange={() => setIncludePromoInForecast(!includePromoInForecast)} />
            </Col>
          </Form.Group>
        </Form>

      </fieldset>

      <Row className='mt-2'>
        <Col
          sm={{ span: 10, offset: 2 }}
          md={{ span: 12, offset: 0 }}
          lg={{ span: 10, offset: 2 }}
        >
          <Form>
            <div>
              <Form.Check inline label='Edit Demand Values' type='radio' radioGroup='edit-series' id='checkbox-edit-sales' onChange={() => setSelectSalesOrForecast(selectSalesOrForecast === 'Demand' ? 'Forecast' : 'Demand')} checked={selectSalesOrForecast === 'Demand' ? true : false} />
              <Form.Check inline label='Edit Forecast Values' type='radio' id='checkbox-edit-forecast' onChange={() => setSelectSalesOrForecast(selectSalesOrForecast === 'Demand' ? 'Forecast' : 'Demand')} checked={selectSalesOrForecast === 'Forecast' ? true : false} />
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
