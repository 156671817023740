import { Form } from 'react-bootstrap'

interface IForecastErrorCOVOptions {
  setOverlayForecastError: any
  overlayForecastError: boolean
}

export default function ForecastErrorCOVOptions({ overlayForecastError, setOverlayForecastError }: IForecastErrorCOVOptions) {


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOverlayForecastError(event.target.checked)
  }

  return (
    <Form>
      <div>
        <Form.Check type='checkbox' id='overlay-checkbox' onChange={handleChange} label='Overlay on Demand COV' />
      </div>
    </Form>
  )
}