import * as d3 from 'd3'
import { useRef, useEffect } from 'react'
import styles from '../../styles/chart.module.scss'

interface ForecastBiasChartProps {
  forecastBiasPercentage: number
  height: number
  width: number
}

export default function ForecastBiasChart ({ forecastBiasPercentage, height, width }: ForecastBiasChartProps) {
  //As the chart scale is from 0 to 200, not -100 to 100.
  const adjustedForecastBiasPercentage = forecastBiasPercentage + 100
  const forecastBiasPercentageForChart = adjustedForecastBiasPercentage < 0 ? 0 : adjustedForecastBiasPercentage > 200 ? 200 : adjustedForecastBiasPercentage

  const ref: any = useRef()

  const drawChart = (data: ForecastBiasChartProps) => {
    const margin = {top: 0, right: 20, bottom: 90, left: 0},
    width = data.width - margin.left - margin.right,
    height = data.height - margin.top - margin.bottom

    const values: Array<[number, number]> = []
    for(let i = 0; i <= 200; i += 10) {
      values.push([ i, 100 ])
    }

    const svg = d3.select(ref.current)
      .attr('class', styles.canvas)
      //.style('height', height)
      //.style('width', width)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `-10 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)

    svg.selectAll('rect,rect.placeholder-bars,circle,path,svg, .axis,defs,.area,text,line').remove()

    const min: number = 0
    const xMax: number = Math.max(...(values.map(o => o[0])))
    const yMax: number = Math.max(...(values.map(o => o[1])))

    const x: any = d3.scaleLinear()
      .domain([min, xMax])
      .range([0, width])

    const y: any = d3.scaleLinear()
      .domain([min, yMax])
      .range([height, 0])

    const area = d3.area()
      .x(function(d) { return x(d[0])})
      .y0(height)
      .y1(function(d) { return y(d[1])})

    svg.append("linearGradient")
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", x(0)).attr("y1", 0)
      .attr("x2", x(xMax)).attr("y2", 0)
      .selectAll("stop").data([ //Does not seem to allow for negative values.
        {offset: "0%", color: "#d9534f77"},
        {offset: "40%", color: "#d9534f77"},
        {offset: "42.5%", color: "#f0ad4e77"},
        {offset: "45%", color: "#f0ad4e77"},
        {offset: "47.5%", color: "#5bb55b77"},
        {offset: "52.5%", color: "#5bb55b77"},
        {offset: "55%", color: "#f0ad4e77"},
        {offset: "57.5%", color: "#f0ad4e77"},
        {offset: "60%", color: "#d9534f77"},
        {offset: "100%", color: "#d9534f77"}
      ])
      .enter().append("stop")
      .attr("offset", function(d) { return d.offset })
      .attr("stop-color", function(d) { return d.color })

    function createTickLabel(d: any, i: number) {
      const actualStartValue = d - 100

      if(actualStartValue === -100) {
        return "< -100%"
      } else if(actualStartValue === 100) {
        return "> 100%"
      }

      return actualStartValue + '%'
    }

    const xAxis = d3.axisBottom(x)
      .ticks(21)
      .tickFormat((d,i) => createTickLabel(d, i))

    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis)
      .selectAll("text")
        .attr("y", 0)
        .attr("x", 9)
        .attr("dy", ".35em")
        .attr("transform", " rotate(90)")
        .style("text-anchor", "start")
        .style('font-size', '16px')

    svg.append("path")
      .data([values])
      .attr("class", "area")
      .attr("d", area)

    svg.append("line")
      .attr("x1", x(forecastBiasPercentageForChart))
      .attr("y1", y(min))
      .attr("x2", x(forecastBiasPercentageForChart))
      .attr("y2", y(yMax))
      .style("stroke-width", 8)
      .style("stroke", "#0000FFA0")
      .style("fill", "none")
  }

  useEffect(() => {
    if (ref.current && ref.current.clientWidth) {
      drawChart({ forecastBiasPercentage, height, width })
    }
    //eslint-disable-next-line
  }, [forecastBiasPercentage, height, width])

  return (
    <svg ref={ref} />
  )
}
