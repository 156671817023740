
import * as XLSX from 'xlsx'
import { SKU, DemandHistory, ForecastHistory } from '../features/DemandAndForecast/DemandAndForecastInterfaces'

export async function uploadCSV(e: any, setUploadedData: Function) {
  const file = e.target.files[0];
  const xlsxFile = await file.arrayBuffer();
  const uploadedData: Array<any> = []

  const workbook = XLSX.read(xlsxFile);
  workbook.SheetNames.forEach(function (sheetName: string) {
    var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1, raw: false, dateNF: 'dd-mm-yyyy' })
    var json_object = JSON.stringify(XL_row_object)
    uploadedData.push(JSON.parse(json_object).slice(1))
  })

  const formattedData = uploadedData[0].map((o: Array<string>, i: number) => ({
    skuCode: parseInt(o[0]),
    warehouseCode: o[1],
    skuName: o[2],
    unitsOfMeasure: o[3],
    sdSupplyError: parseInt(o[4]),
    sdStrippingThreshold: parseInt(o[5]),
    leadTimeDays: parseInt(o[6]),
    replenishmentReviewFrequencyDays: parseInt(o[7]),
    averageReplenishmentUnits: parseInt(o[8]),
    availabilityTarget: parseFloat(o[9]),
    demandHistory: uploadedData[1].filter((d: Array<string>) => d[0] === o[0] && d[1] === o[1]).map((d: Array<string>) => ({
      skuCode: parseInt(d[0]),
      warehouseCode: d[1],
      date: new Date(d[2]),
      rawDemandQuantity: parseInt(d[3]),
      overriddenDemandQuantity: parseInt(d[4]),
      demandIsKnockedOut: d[5].toUpperCase() === 'TRUE'
    } as DemandHistory)),
    forecastHistory: uploadedData[2].filter((d: Array<any>) => d[0] === o[0] && d[1] === o[1]).map((d: Array<any>) => ({
      skuCode: parseInt(d[0]),
      warehouseCode: d[1],
      date: new Date(d[2]),
      rawForecastQuantity: parseInt(d[3]),
      overriddenForecastQuantity: parseInt(d[4]),
      forecastIsKnockedOut: d[5].toUpperCase() === 'TRUE'
    } as ForecastHistory)),
  } as SKU)) as Array<SKU>

  const cleansedData = formattedData.map(o => cleanSKUHistories(o))
  setUploadedData(cleansedData)
};

export function cleanSKUHistories(sku: SKU) {
  const dates = sku.demandHistory.map(o => o.date.getTime()).concat(sku.forecastHistory.map(o => o.date.getTime()))
  const earliestDate = Math.min.apply(null, dates)
  const latestDate = Math.max.apply(null, dates)
  const trackerDate = new Date(earliestDate)
  let index = 0

  while (trackerDate.getTime() <= latestDate) {
    if (!sku.demandHistory.find(o => o.date.getTime() === trackerDate.getTime())) {
      sku.demandHistory.splice(index, 0, {
        skuCode: sku.skuCode,
        warehouseCode: sku.warehouseCode,
        date: new Date(trackerDate),
        rawDemandQuantity: 0,
        overriddenDemandQuantity: 0,
        demandIsKnockedOut: false
      } as DemandHistory)
    }

    if (!sku.forecastHistory.find(o => o.date.getTime() === trackerDate.getTime())) {
      sku.forecastHistory.splice(index, 0, {
        skuCode: sku.skuCode,
        warehouseCode: sku.warehouseCode,
        date: new Date(trackerDate),
        rawForecastQuantity: 0,
        overriddenForecastQuantity: 0,
        forecastIsKnockedOut: false
      } as ForecastHistory)
    }

    index += 1

    trackerDate.setDate(trackerDate.getDate() + 1)
  }

  return sku
}

export function downloadToCSV(uploadedData: Array<SKU>) {
  var wb = XLSX.utils.book_new()
  var skuWorksheet = XLSX.utils.json_to_sheet(uploadedData.map((o) => {
    return {
      "SKU Code": o.skuCode,
      "Warehouse Code/Name": o.warehouseCode,
      "SKU Name": o.skuName,
      "Units of Measure": o.unitsOfMeasure,
      "Sd Supply Error": o.sdSupplyError,
      "Sd Stripping Threshold": o.sdStrippingThreshold,
      "Lead Time Days": o.leadTimeDays,
      "Replenishment Review Frequency Days": o.replenishmentReviewFrequencyDays,
      "Average Replenishment Units": o.averageReplenishmentUnits,
      "Availability Target": o.availabilityTarget
    }
  }))

  var demandHistoryWorksheet = XLSX.utils.json_to_sheet(uploadedData.map((o) => o.demandHistory).flat().map((o) => {
    return {
      "SKUCode": o.skuCode,
      "Warehouse Code/Name": o.warehouseCode,
      "Date": o.date,
      "Raw Demand Quantity": o.rawDemandQuantity,
      "Overridden Demand Quantity": o.overriddenDemandQuantity,
      "Is Knocked Out?": o.demandIsKnockedOut
    }
  }))

  var forecastHistoryWorksheet = XLSX.utils.json_to_sheet(uploadedData.map((o) => o.forecastHistory).flat().map((o) => {
    return {
      "SKUCode": o.skuCode,
      "Warehouse Code/Name": o.warehouseCode,
      "Date": o.date,
      "Raw Forecast Quantity": o.rawForecastQuantity,
      "Overridden Forecast Quantity": o.overriddenForecastQuantity,
      "Is Knocked Out?": o.forecastIsKnockedOut
    }
  }))

  XLSX.utils.book_append_sheet(wb, skuWorksheet, 'SKUs')
  XLSX.utils.book_append_sheet(wb, demandHistoryWorksheet, 'Demand History')
  XLSX.utils.book_append_sheet(wb, forecastHistoryWorksheet, 'Forecast History')
  XLSX.writeFile(wb, 'download.xlsx')
}

export function resetSKU(uploadedData: Array<SKU>, setUploadedData: Function, selectedSKUIndex: number) {
  const rowToUpdate = uploadedData[selectedSKUIndex]
  if (rowToUpdate === undefined) { return }

  rowToUpdate.demandHistory = rowToUpdate.demandHistory.map(o => { return { ...o, overriddenDemandQuantity: o.rawDemandQuantity } })
  rowToUpdate.forecastHistory = rowToUpdate.forecastHistory.map(o => { return { ...o, overriddenForecastQuantity: o.rawForecastQuantity } })

  const newUploadedData = uploadedData.map((o, i) => i === rowToUpdate.skuCode ? rowToUpdate : o)
  setUploadedData(newUploadedData)
}

export function addDataPointToSKU(uploadedData: Array<SKU>, setUploadedData: Function, selectedSKUIndex: number | undefined) {
  if (selectedSKUIndex === undefined) { return }

  const rowToUpdate = uploadedData[selectedSKUIndex]
  if (!rowToUpdate) { return }

  const finalDate = new Date(
    Math.max.apply(null,
      rowToUpdate.demandHistory.map(o => o.date.getTime())
        .concat(rowToUpdate.forecastHistory.map(o => o.date.getTime()))
    ))
  finalDate.setDate(finalDate.getDate() + 1)

  const demandQuantity = Math.floor(Math.random() * (700 - 1 + 1) + 1)
  const forecastQuantity = Math.floor(Math.random() * (700 - 1 + 1) + 1)

  rowToUpdate.demandHistory.push({ skuCode: rowToUpdate.skuCode, warehouseCode: rowToUpdate.warehouseCode, date: new Date(finalDate), rawDemandQuantity: demandQuantity, overriddenDemandQuantity: demandQuantity, demandIsKnockedOut: false })
  rowToUpdate.forecastHistory.push({ skuCode: rowToUpdate.skuCode, warehouseCode: rowToUpdate.warehouseCode, date: new Date(finalDate), rawForecastQuantity: forecastQuantity, overriddenForecastQuantity: forecastQuantity, forecastIsKnockedOut: false })

  const newUploadedData = uploadedData.map((o, i) => i === selectedSKUIndex ? rowToUpdate : o)
  setUploadedData(newUploadedData)
}

export function getPreloadedData() {
  return [
    {
      "skuCode": 1,
      "warehouseCode": "NE-56",
      "skuName": "SKU 1A",
      "unitsOfMeasure": "g",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 20,
          "overriddenDemandQuantity": 20,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 25,
          "overriddenDemandQuantity": 25,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 23,
          "overriddenDemandQuantity": 23,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 15,
          "overriddenDemandQuantity": 15,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 10,
          "overriddenForecastQuantity": 10,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 10,
          "overriddenForecastQuantity": 10,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 30,
          "overriddenForecastQuantity": 30,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-56",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 28,
          "overriddenForecastQuantity": 28,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 1,
      "warehouseCode": "NE-57",
      "skuName": "SKU 1B",
      "unitsOfMeasure": "g",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 15,
          "overriddenDemandQuantity": 15,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 23,
          "overriddenDemandQuantity": 23,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 25,
          "overriddenDemandQuantity": 25,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 20,
          "overriddenDemandQuantity": 20,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 28,
          "overriddenForecastQuantity": 28,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 30,
          "overriddenForecastQuantity": 30,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 10,
          "overriddenForecastQuantity": 10,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 1,
          "warehouseCode": "NE-57",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 10,
          "overriddenForecastQuantity": 10,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 2,
      "warehouseCode": "NE-42",
      "skuName": "SKU 2A",
      "unitsOfMeasure": "kg",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-06T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 1,
          "overriddenDemandQuantity": 1,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 2,
          "overriddenDemandQuantity": 2,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 1,
          "overriddenDemandQuantity": 1,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-06T23:00:00.000Z",
          "rawForecastQuantity": 1,
          "overriddenForecastQuantity": 1,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 1,
          "overriddenForecastQuantity": 1,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 2,
          "overriddenForecastQuantity": 2,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-42",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 2,
      "warehouseCode": "NE-43",
      "skuName": "SKU 2B",
      "unitsOfMeasure": "kg",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-06T23:00:00.000Z",
          "rawDemandQuantity": 1,
          "overriddenDemandQuantity": 1,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 2,
          "overriddenDemandQuantity": 2,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 1,
          "overriddenDemandQuantity": 1,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-06T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 2,
          "overriddenForecastQuantity": 2,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 1,
          "overriddenForecastQuantity": 1,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 2,
          "warehouseCode": "NE-43",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 1,
          "overriddenForecastQuantity": 1,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 3,
      "warehouseCode": "LND-2",
      "skuName": "SKU 3",
      "unitsOfMeasure": "l",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 57,
          "overriddenDemandQuantity": 57,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 120,
          "overriddenDemandQuantity": 120,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 83,
          "overriddenDemandQuantity": 83,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 68,
          "overriddenDemandQuantity": 68,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 30,
          "overriddenForecastQuantity": 30,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 40,
          "overriddenForecastQuantity": 40,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 90,
          "overriddenForecastQuantity": 90,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 3,
          "warehouseCode": "LND-2",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 150,
          "overriddenForecastQuantity": 150,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 4,
      "warehouseCode": "NE-9",
      "skuName": "SKU 4A",
      "unitsOfMeasure": "kg",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 10,
          "overriddenDemandQuantity": 10,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 17,
          "overriddenDemandQuantity": 17,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 8,
          "overriddenDemandQuantity": 8,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 12,
          "overriddenDemandQuantity": 12,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 20,
          "overriddenForecastQuantity": 20,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 20,
          "overriddenForecastQuantity": 20,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 16,
          "overriddenForecastQuantity": 16,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-9",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 12,
          "overriddenForecastQuantity": 12,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 4,
      "warehouseCode": "NE-10",
      "skuName": "SKU 4B",
      "unitsOfMeasure": "kg",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 12,
          "overriddenDemandQuantity": 12,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 8,
          "overriddenDemandQuantity": 8,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 17,
          "overriddenDemandQuantity": 17,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 10,
          "overriddenDemandQuantity": 10,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 12,
          "overriddenForecastQuantity": 12,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 16,
          "overriddenForecastQuantity": 16,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 20,
          "overriddenForecastQuantity": 20,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-10",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 20,
          "overriddenForecastQuantity": 20,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 4,
      "warehouseCode": "NE-11",
      "skuName": "SKU 4C",
      "unitsOfMeasure": "kg",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 17,
          "overriddenDemandQuantity": 17,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 10,
          "overriddenDemandQuantity": 10,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 12,
          "overriddenDemandQuantity": 12,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 8,
          "overriddenDemandQuantity": 8,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 20,
          "overriddenForecastQuantity": 20,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 16,
          "overriddenForecastQuantity": 16,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 12,
          "overriddenForecastQuantity": 12,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 4,
          "warehouseCode": "NE-11",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 20,
          "overriddenForecastQuantity": 20,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 5,
      "warehouseCode": "SC-3",
      "skuName": "SKU 5",
      "unitsOfMeasure": "in",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-06T23:00:00.000Z",
          "rawDemandQuantity": 430,
          "overriddenDemandQuantity": 430,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 246,
          "overriddenDemandQuantity": 246,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 699,
          "overriddenDemandQuantity": 699,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 514,
          "overriddenDemandQuantity": 514,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 165,
          "overriddenDemandQuantity": 165,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-06T23:00:00.000Z",
          "rawForecastQuantity": 500,
          "overriddenForecastQuantity": 500,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 200,
          "overriddenForecastQuantity": 200,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 650,
          "overriddenForecastQuantity": 650,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 550,
          "overriddenForecastQuantity": 550,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 5,
          "warehouseCode": "SC-3",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 200,
          "overriddenForecastQuantity": 200,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 70,
      "warehouseCode": "NE-50",
      "skuName": "SKU DATES TEST 1",
      "unitsOfMeasure": "DateTime",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-07-31T23:00:00.000Z",
          "rawDemandQuantity": 100,
          "overriddenDemandQuantity": 100,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-01T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-02T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-03T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-04T23:00:00.000Z",
          "rawDemandQuantity": 100,
          "overriddenDemandQuantity": 100,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-05T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-06T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-11T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-12T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-13T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-14T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-15T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-16T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-17T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-18T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-19T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-20T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-21T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-22T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-23T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-24T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-25T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-26T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-27T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-28T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-29T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-30T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-31T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-01T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-02T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-03T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-04T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-05T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-06T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-08T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-11T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-12T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-13T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-14T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-15T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-16T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-17T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-18T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-19T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-20T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-21T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-22T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-23T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-24T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-25T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-26T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-27T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-28T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-29T23:00:00.000Z",
          "rawDemandQuantity": 100,
          "overriddenDemandQuantity": 100,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-30T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-01T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-02T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-03T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-04T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-05T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-06T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-08T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-11T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-12T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-13T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-14T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-15T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-16T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-17T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-18T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-19T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-20T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-21T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-22T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-23T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-24T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-25T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-26T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-27T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-28T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-29T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-31T00:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-07-31T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-01T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-02T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-03T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-04T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-05T23:00:00.000Z",
          "rawForecastQuantity": 100,
          "overriddenForecastQuantity": 100,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-06T23:00:00.000Z",
          "rawForecastQuantity": 100,
          "overriddenForecastQuantity": 100,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-11T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-12T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-13T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-14T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-15T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-16T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-17T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-18T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-19T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-20T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-21T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-22T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-23T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-24T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-25T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-26T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-27T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-28T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-29T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-30T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-08-31T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-01T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-02T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-03T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-04T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-05T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-06T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-08T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-11T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-12T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-13T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-14T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-15T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-16T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-17T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-18T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-19T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-20T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-21T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-22T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-23T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-24T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-25T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-26T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-27T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-28T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-29T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-09-30T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-01T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-02T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-03T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-04T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-05T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-06T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-08T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-11T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-12T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-13T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-14T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-15T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-16T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-17T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-18T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-19T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-20T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-21T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-22T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-23T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-24T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-25T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-26T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-27T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-28T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-29T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-50",
          "date": "2022-10-31T00:00:00.000Z",
          "rawForecastQuantity": 100,
          "overriddenForecastQuantity": 100,
          "forecastIsKnockedOut": false
        }
      ]
    },
    {
      "skuCode": 70,
      "warehouseCode": "NE-51",
      "skuName": "SKU DATES TEST 2",
      "unitsOfMeasure": "DateTime",
      "sdSupplyError": 1,
      "sdStrippingThreshold": 3,
      "leadTimeDays": 20,
      "replenishmentReviewFrequencyDays": 7,
      "averageReplenishmentUnits": 800,
      "availabilityTarget": 0.985,
      "demandHistory": [
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-07-31T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-01T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-02T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-03T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-04T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-05T23:00:00.000Z",
          "rawDemandQuantity": 100,
          "overriddenDemandQuantity": 100,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-06T23:00:00.000Z",
          "rawDemandQuantity": 100,
          "overriddenDemandQuantity": 100,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-08T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-11T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-12T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-13T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-14T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-15T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-16T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-17T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-18T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-19T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-20T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-21T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-22T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-23T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-24T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-25T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-26T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-27T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-28T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-29T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-30T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-31T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-01T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-02T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-03T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-04T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-05T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-06T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-08T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-11T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-12T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-13T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-14T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-15T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-16T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-17T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-18T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-19T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-20T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-21T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-22T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-23T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-24T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-25T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-26T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-27T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-28T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-29T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-30T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-01T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-02T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-03T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-04T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-05T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-06T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-07T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-08T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-09T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-10T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-11T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-12T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-13T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-14T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-15T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-16T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-17T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-18T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-19T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-20T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-21T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-22T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-23T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-24T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-25T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-26T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-27T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-28T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-29T23:00:00.000Z",
          "rawDemandQuantity": 0,
          "overriddenDemandQuantity": 0,
          "demandIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-31T00:00:00.000Z",
          "rawDemandQuantity": 100,
          "overriddenDemandQuantity": 100,
          "demandIsKnockedOut": false
        }
      ],
      "forecastHistory": [
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-07-31T23:00:00.000Z",
          "rawForecastQuantity": 100,
          "overriddenForecastQuantity": 100,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-01T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-02T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-03T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-04T23:00:00.000Z",
          "rawForecastQuantity": 100,
          "overriddenForecastQuantity": 100,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-05T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-06T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-08T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-11T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-12T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-13T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-14T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-15T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-16T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-17T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-18T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-19T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-20T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-21T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-22T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-23T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-24T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-25T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-26T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-27T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-28T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-29T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-30T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-08-31T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-01T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-02T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-03T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-04T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-05T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-06T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-08T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-11T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-12T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-13T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-14T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-15T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-16T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-17T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-18T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-19T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-20T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-21T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-22T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-23T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-24T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-25T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-26T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-27T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-28T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-29T23:00:00.000Z",
          "rawForecastQuantity": 100,
          "overriddenForecastQuantity": 100,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-09-30T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-01T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-02T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-03T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-04T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-05T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-06T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-07T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-08T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-09T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-10T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-11T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-12T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-13T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-14T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-15T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-16T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-17T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-18T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-19T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-20T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-21T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-22T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-23T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-24T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-25T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-26T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-27T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-28T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-29T23:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        },
        {
          "skuCode": 70,
          "warehouseCode": "NE-51",
          "date": "2022-10-31T00:00:00.000Z",
          "rawForecastQuantity": 0,
          "overriddenForecastQuantity": 0,
          "forecastIsKnockedOut": false
        }
      ]
    }
  ]
  .map(o => { return {
    ...o,
    demandHistory: o.demandHistory.map(d => { return { ...d, date: new Date(d.date) }}),
    forecastHistory: o.forecastHistory.map(f => { return { ...f, date: new Date(f.date) }})
  }})
}

export function getForecastBiasColour(bias: number) {
  if (bias >= -10 && bias <= 10)
    return '#5bb55b77'
  if (bias >= -20 && bias <= -10)
    return '#f0ad4e77'
  if (bias >= 10 && bias <= 20)
    return '#f0ad4e77'
  if (bias >= 20 || bias <= 20)
    return '#d9534f77'
  else
    return '#d9534f77'
}

export function getFVAColour(demandCOV: number, forecastErrorCOV: number) {
  if ((Math.abs(demandCOV - forecastErrorCOV) / demandCOV) <= 0.1)
    return '#f0ad4e77'
  else if(demandCOV > forecastErrorCOV)
    return '#5bb55b77'
  else
    return '#d9534f77'
}
