import { Col, Row } from "react-bootstrap";

interface ValueDisplayBoxProps {
  displayValue: string,
  valueDescription: string,
  backgroundColor: string
}

export default function ValueDisplayBox ({ displayValue, valueDescription, backgroundColor }: ValueDisplayBoxProps) {
  return (
    <div style={{ backgroundColor: backgroundColor, width: 90, height: 80, padding: 5, margin: 5, position: 'relative' }}>
      <Row>
        <Col style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center', fontSize: 24 }}>
          {displayValue}
        </Col>
      </Row>
      <Row style={{ position: 'absolute', bottom: 2, width: '100%', margin: 'auto' }}>
        <Col style={{ color: '#FFFFFF', textAlign: 'right', fontSize: 12 }}>
          {valueDescription}
        </Col>
      </Row>
    </div>
  )
}
