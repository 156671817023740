import React, { useEffect, useMemo, useState } from 'react'
import { Button, Container, Form, InputGroup } from 'react-bootstrap'
import { CalculateNoise, CalculateSeasonaility, CalculatePromo, CalculateTrend } from '../../utils/calculations'

interface ChartProps {
  label: string
  value: number
  type: string
}

const perfectForecast = (arrayLength: number, averageDemandValue: number, includeSeasonaility: boolean, includeTrend: boolean, includePromo: boolean, seasonaility: number[], trend: number[], promo: number[]) => {
  return Array.apply(null, Array(arrayLength)).map((_, i): ChartProps =>
  (
    {
      label: i.toString(),
      value: (averageDemandValue +
        (includeSeasonaility ? seasonaility[i] : 0) +
        (includeTrend ? trend[i] : 0) +
        (includePromo ? promo[i] : 0)),
      type: 'Forecast'
    }
  ))
}
const demand = (perfectForecast: ChartProps[], includeNoise: boolean, noise: number[]) => {
  return [...perfectForecast]
    .map((o: ChartProps, i) =>
    (
      {
        ...o,
        value: (o.value + (includeNoise ? noise[i] : 0)),
        type: 'Demand'
      }
    ))
}

export default function DemandVariabilityOptions({ setChartData }: any) {
  const [includeNoise, setIncludeNoise] = useState<boolean>(true)
  const [includeSeasonaility, setIncludeSeasonaility] = useState<boolean>(false)
  const [includeTrend, setIncludeTrend] = useState<boolean>(false)
  const [includePromo, setIncludePromo] = useState<boolean>(false)
  const [showPerfectForecast, setShowPerfectForecast] = useState<boolean>(false)

  const arrayLength = 60
  const averageDemandValue = 1000
  const [covValue, setCovValue] = useState<number>(40)
  const [seasonailityValue, setSeasonailityValue] = useState<number>(500)
  const [trendValue, setTrendValue] = useState<number>(20)
  const [promoValue, setPromoValue] = useState<number>(1000)
  const [goButtonPressed, setGoButtonPressed] = useState<boolean>(false)

  const noise: number[] = useMemo<number[]>(() => {
    if (goButtonPressed) {
      setGoButtonPressed(!goButtonPressed)
    }
    return CalculateNoise(covValue / 100 * averageDemandValue, arrayLength)
  }, [covValue, averageDemandValue, arrayLength, goButtonPressed, setGoButtonPressed])

  const seasonaility: number[] = useMemo<number[]>(() => CalculateSeasonaility(seasonailityValue, arrayLength), [seasonailityValue, arrayLength])
  const trend: number[] = useMemo<number[]>(() => CalculateTrend(trendValue, averageDemandValue, arrayLength), [trendValue, averageDemandValue, arrayLength])
  const promo: number[] = useMemo<number[]>(() => CalculatePromo(promoValue, arrayLength), [promoValue, arrayLength])

  const perfectForecastValues = useMemo(() => perfectForecast(arrayLength, averageDemandValue, includeSeasonaility, includeTrend, includePromo, seasonaility, trend, promo), [arrayLength, averageDemandValue, includeSeasonaility, includeTrend, includePromo, seasonaility, trend, promo])

  const demandValues = useMemo(() => demand(perfectForecastValues, includeNoise, noise), [perfectForecastValues, includeNoise, noise])

  useEffect(() => {
    setChartData(showPerfectForecast ? demandValues.concat(...perfectForecastValues) : demandValues)
  }, [setChartData, showPerfectForecast, demandValues, perfectForecastValues])


  return (
    <Container>

      <Form>
        <Form.Group className="mb-3">
          <Form.Label htmlFor='average-demand'>Average Demand</Form.Label>
          <InputGroup>
            <Form.Control id='average-demand' placeholder='Average Demand' disabled value={averageDemandValue} />
            <InputGroup.Text>units</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor='cov-demand'><abbr title="Coefficient of Variability">COV</abbr></Form.Label>
          <Form.Check inline reverse type='switch' className='float-end me-0' aria-label='show COV' id='include-cov-demand' checked={includeNoise} onChange={() => setIncludeNoise(!includeNoise)} />
          <InputGroup>
            <Form.Control id='cov-demand' placeholder='COV' type='number' step={10} min={0} max={100} value={covValue} onChange={(e): void => setCovValue(parseInt(e.target.value))} />
            <InputGroup.Text>%</InputGroup.Text>
            <Button variant='primary' onClick={() => setGoButtonPressed(!goButtonPressed)}>
              <span className='bi bi-arrow-clockwise' role='img' aria-label='refresh'></span>
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor='seasonaility-demand'>Seasonality</Form.Label>
          <Form.Check inline reverse type='switch' className='float-end me-0' aria-label='show seasonaility' id='include-seasonaility-demand' checked={includeSeasonaility} onChange={() => setIncludeSeasonaility(!includeSeasonaility)} />
          <InputGroup>
            <Form.Control id='seasonaility-demand' placeholder='Seasonality' type='number' step={100} min={0} max={500} value={seasonailityValue} onChange={(e): void => setSeasonailityValue(parseInt(e.target.value))} />
            <InputGroup.Text>units</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor='trend-demand'>Trend</Form.Label>
          <Form.Check inline reverse type='switch' className='float-end me-0' aria-label='show trend' id='include-trend-demand' checked={includeTrend} onChange={() => setIncludeTrend(!includeTrend)} />
          <InputGroup>
            <Form.Control id='trend-demand' placeholder='Trend' type='number' step={10} min={0} max={50} value={trendValue} onChange={(e): void => setTrendValue(parseInt(e.target.value))} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor='promo-demand'>Promo</Form.Label>
          <Form.Check inline reverse type='switch' className='float-end me-0' aria-label='show promo' id='include-promo-demand' checked={includePromo} onChange={() => setIncludePromo(!includePromo)} />
          <InputGroup>
            <Form.Control id='promo-demand' placeholder='Promo' type='number' step={100} min={0} max={1000} value={promoValue} onChange={(e): void => setPromoValue(parseInt(e.target.value))} />
            <InputGroup.Text>units</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Check label='Show perfect forecast' type='switch' id='checkbox-show-forecast' onChange={() => setShowPerfectForecast(!showPerfectForecast)} checked={showPerfectForecast} />

      </Form>

    </Container>
  )
}
