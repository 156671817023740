import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name: 'token',
    initialState: null as string | null,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string | null>) => action.payload
    }
})

const { actions, reducer } = tokenSlice

export const { setAccessToken } = actions


export default reducer