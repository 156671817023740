import { DataGrid } from '@mui/x-data-grid'
import { StockTarget } from './DemandAndForecastInterfaces'

interface StockTargetTableProps {
  stockTarget: StockTarget
  showValueRow: boolean
}

export default function StockTargetTable ({ stockTarget, showValueRow }: StockTargetTableProps) {
  if (!stockTarget) { return <></> }

  const tableData = [
    {
      title: 'Units (CASES)',
      safety: stockTarget.safetyStockUnits.toFixed(2),
      cycle: stockTarget.cycleStockUnits.toFixed(2),
      average: stockTarget.averageStockUnits.toFixed(2)
    },
    {
      title: 'Days',
      safety: stockTarget.safetyStockDays.toFixed(2),
      cycle: stockTarget.cycleStockDays.toFixed(2),
      average: stockTarget.averageStockDays.toFixed(2)
    }
  ]
  if(showValueRow) {
    tableData.push({
      title: 'Value',
      safety: stockTarget.safetyStockValue ? stockTarget.safetyStockValue.toFixed(2) : '-',
      cycle: stockTarget.cycleStockValue ? stockTarget.cycleStockValue.toFixed(2) : '-',
      average: stockTarget.averageStockValue ? stockTarget.averageStockValue.toFixed(2) : '-'
    })
  }

  const columns = [{
    field: 'title',
    headerName: '',
    flex: 1.2,
    type: 'string'
  }, {
    field: 'safety',
    headerName: 'Safety Stock',
    flex: 1,
    type: 'string'
  }, {
    field: 'cycle',
    headerName: 'Cycle Stock',
    flex: 1,
    type: 'string'
  }, {
    field: 'average',
    headerName: 'Average Stock',
    flex: 1,
    type: 'string'
  }]

  return (
    <DataGrid
      rows={tableData}
      columns={columns}
      autoHeight
      getRowId={(row) => row.title}
      density='compact'
      disableSelectionOnClick
      hideFooter
    />
  )
}