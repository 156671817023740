import * as d3 from 'd3'
import { nest } from 'd3-collection'
import { useRef, useEffect } from 'react'
import styles from '../../styles/chart.module.scss'

interface ChartProps {
  label: string
  value: number
  type: string
}

interface IDemandVariability {
  props: ChartProps[]
}

export default function DemandVariabilityChart ({ props }: IDemandVariability) {
  const data = props

  const margin: any = { top: 10, right: 10, bottom: 20, left: 10 }
  const width: number = 1000
  const height: number = 500

  const ref: any = useRef()

  const drawChart = (data: ChartProps[]) => {
    const sumstat = nest()
      .key((d: any) => d.type)
      .entries(data)

    const svg = d3
      .select(ref.current)
      .attr('class', styles.canvas)
      .attr('preserveAspectRatio', 'xMinYMin')
      .attr('viewBox', `-40 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)

    svg
      .selectAll('rect,rect.placeholder-bars,.line-datapoint,line.placeholder-line,#legend,#watermark,circle')
      .remove()

    const selection = svg
      .selectAll('rect')
      .data(sumstat)

    const min: number = 0 //Math.min(...data.map(o => o.value)) //* 0.8
    const max: number = 4100 //Math.max(...data.map(o => o.value)) * 1.2

    const y: any = d3
      .scaleLinear()
      .domain([min, max])
      .range([height - margin.top + margin.bottom, 0])

    const x: any = d3
      .scaleBand()
      .domain(data.map(o => o.label))
      .range([0, (width - (margin.left + margin.right))])

    selection
      .enter()
      .append('g')
      .attr('class', 'grid')

    selection
      .enter()
      .append('g')
      .attr('id', 'yAxis')
      .attr('color', '#b3b3b3')
      .call(y)

    const yAXIS: any = d3
      .axisLeft(y)
      .ticks(8)

    svg
      .select('#yAxis')
      .attr('class', styles.yaxis)
      .transition()
      .call(yAXIS)

    svg
      .selectAll('.lineTest')
      .remove()

    let line: any = svg
      .selectAll('.lineTest')
      .data([data], function (d: any) { return d.label })

    line = line
      .data(sumstat)
      .enter()
      .append('path')
      .attr('class', 'lineTest')
      .merge(line)

    const color: any = d3
      .scaleOrdinal()
      .domain(['Demand', 'Forecast'])
      .range(['#287e7e', '#e9841d'])

    const valueline = (d: any) => d3
      .line()
      .x((d: any) => x(d.label))
      .y((d: any) => y(d.value))
      (d.values)

    line
      .attr('d', (d: any) => valueline(d))
      .attr('id', function (d: any) { return d.key + '-line' })
      .attr('data-testid', function (d: any) { return d.key + '-line' })
      .attr('stroke', (d: any, i: number) => color(d.key))
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')

    // let circles =
    svg
      .selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      // .style('cursor', 'pointer')
      .style('z-index', '-1')
      .style('fill', '#ffffff')
      .style('stroke', (d: any, i: number) => color(d.type))
      .style('stroke-width', 2)
      .attr('cx', function (d: any) { return x(d.label) })
      .attr('cy', function (d) { return y(d.value) })
      .attr('r', 4)

    const legendHolder = svg
      .append('g')
      .attr('transform', 'translate(' + (-width) + ',' + (-margin.top) + ')')

    const legend = legendHolder
      .selectAll('.legendHolder')
      .data(sumstat.slice())
      .enter()
      .append('g')
      .attr('width', 36)

    legend
      .append('rect')
      .attr('x', (d: any, i: number) => (width + (150 * i)))
      .attr('y', height + margin.bottom + margin.top)
      .attr('dy', '-0.5em')
      .attr('width', 15)
      .attr('height', 3)
      .attr('fill', (d: any, i: number) => color(d.key))

    legend
      .append('text')
      .attr('x', (d, i) => (width + (150 * i) + 20))
      .attr('y', height + margin.bottom + margin.top)
      .attr('dy', '.5em')
      .text(d => d.key)
      .attr('fill', '#333333')
      .attr('id', 'legend')
      .attr('class', styles.legend)

    /**
     * DRAGGABLE FUNCTIONALITY - START
     *
     * Functionality request - enable draggable datapoints. This functionality wasn't requested for a particular chart or to be a part of a new component.
     * This functionality was attached to this component for proof of concept (uncomment to see in action).
     *
     *

    let brush = d3.brush()
      .extent([
        [0, 0],
        [width, height - margin.top + margin.bottom]
      ])
      .on('start end', d => selectCircles(d))
      .on('brush', d => moveCoords(d))

    svg.append('g')
      .attr('class', 'brush')
      .call(brush);

    let chartConfig = {
      'x_axis': ['label'],
      'y_axis': ['value'],
      'groupBy': ['type']
    }
    let xAxisAttr = chartConfig.x_axis[0]

    let yAxisAttr = chartConfig.y_axis[0]

    let coordsObj: any = {}

    function moveCoords(event: any) {

      let coords: any = event.selection;
      if (!coords) return;
      let xDeltaNew: any = coords[0][0] - coords[1][0]
      let yDeltaNew: any = (coords[0][1]) - (coords[1][1]);

      if (coordsObj.xDelta === xDeltaNew && coordsObj.yDelta === yDeltaNew) {

        d3.selectAll('.selected').attr('cy', (d: any) => {

          if (!d.originalY) {
            d.originalY = y(d[yAxisAttr]);
          }

          let currentCoord = (coordsObj.yPos - coords[0][1])

          let yCoordinate = (d.originalY - currentCoord) < 0 ? 1 : (d.originalY - currentCoord)

          d[yAxisAttr] = y.invert(yCoordinate)

          line.attr('d', (d: any) => valueline(d))

          circles.attr('cx', function (d: any) { return x(d.label) })
            .attr('cy', function (d) { return y(d.value) })

          return yCoordinate;
        })
          ;
      }
    }

    function selectCircles(event: any) {
      let coords: any = event.selection;

      if (!coords) return;
      coordsObj.xDelta = coords[0][0] - coords[1][0];
      coordsObj.yDelta = (coords[0][1]) - (coords[1][1]);
      coordsObj.yPos = (coords[0][1])

      d3.selectAll('circle').style("fill", "white")

      circles.classed('selected', (d: any, xAxis: any) => {

        delete d.originalY;

        d3.selectAll('.selected')
          .style('fill', 'darkgreen');

        return isBrushed(coords, x(d[xAxisAttr]), y(d[yAxisAttr]))
      });
    }

    function isBrushed(coords: any, cx: any, cy: any): any {
      let x0 = coords[0][0]
      let x1 = coords[1][0]
      let y0 = coords[0][1]
      let y1 = coords[1][1]

      return x0 <= cx && cx <= x1 && y0 <= cy && cy <= y1;
    }
    *
    * DRAGGABLE FUNCTIONALITY - END
    * */
  }

  useEffect(() => {
    if (ref.current && ref.current.clientWidth) {
      drawChart(data)
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <svg data-testid='demandVariability-test-id' ref={ref} />
  )
}
