import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { TokenContext } from "../../utils/TokenContext";

interface IStockServiceTradeOffOptions {
  stockServiceTradeOffChartData: any;
  setStockServiceTradeOffChartData: any;
}

function generateAvailabilityTargets() {
  let availabilityTargets: number[] = [];
  for (var i = 0.97; i <= 0.999; i += 0.001) {
    availabilityTargets.push(i);
  }
  availabilityTargets = availabilityTargets.concat([
    0.9995, 0.9999, 0.99995, 0.99999,
  ]);
  return availabilityTargets;
}

export default function StockServiceTradeOffOptions({
  stockServiceTradeOffChartData,
  setStockServiceTradeOffChartData,
}: IStockServiceTradeOffOptions) {
  let availabilityTargets: number[] = useMemo(
    () => generateAvailabilityTargets(),
    []
  );

  const [demandValue, setDemandValue] = useState<number>(200);
  const [replenishmentUnitsValue, setReplenishmentUnitsValue] =
    useState<number>(500);
  const [costOfCapitalValue, setCostOfCapitalValue] = useState<number>(5);
  const [disposalCostPerUnitValue, setDisposalCostPerUnitValue] =
    useState<number>(0);
  const [elasticityValue, setElasticityValue] = useState<number>(200);
  const [iadoValue, setIADOValue] = useState<number>(5);
  const [leadTimeDaysValue, setLeadTimeDaysValue] = useState<number>(30);
  const [netSalesPerUnitValue, setNetSalesPerUnitValue] = useState<number>(100);
  const [
    percentageOfVariableCostLostOnObsolescenceValue,
    setPercentageOfVaraibleCostLostOnObsolescenceValue,
  ] = useState<number>(25);
  const [
    replenishmentReviewFrequencyDaysValue,
    setReplenishmentReviewFrequencyDaysValue,
  ] = useState<number>(7);
  const [demandCovValue, setDemandCovValue] = useState<number>(100);
  const [forecastErrorCovValue, setForecastErrorCovValue] =
    useState<number>(100);
  const [shelfLifeDaysValue, setShelfLifeDaysValue] = useState<number>(60);
  const [storageCostPerUnitValue, setStorageCostPerUnitValue] =
    useState<number>(100);
  const [variableCostPerUnitValue, setVariableCostPerUnitValue] =
    useState<number>(30);

  const [goButtonPressed, setGoButtonPressed] = useState<boolean>(false);

  const accessToken = useContext(TokenContext);

  const stockOptimisationApiCall = useCallback(
    debounce((dataInput) => {
      fetch(
        `${process.env.REACT_APP_API_ADDRESS}/api/ImmediateService/GetStockOptimisationChart`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": process.env.REACT_APP_ADDRESS ?? "",
            "AccessControl-Allow-Headers": "*",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(dataInput),
        }
      )
        .then(async (data) => await data.json())
        .then((data) =>
          setStockServiceTradeOffChartData({
            result: data.result,
            alertProcess: data.alertProcess,
          })
        )
        .catch((err) => console.log(err));
    }, 100),
    [accessToken]
  );

  useEffect(() => {
    if (
      stockServiceTradeOffChartData.result === undefined ||
      stockServiceTradeOffChartData.result.length === 0 ||
      goButtonPressed
    ) {
      const dataInput = {
        availabilityTargets: availabilityTargets,
        averageDemand: demandValue,
        averageReplenishmentUnits: replenishmentUnitsValue,
        costOfCapital: costOfCapitalValue / 100,
        disposalCostPerUnit: disposalCostPerUnitValue,
        elasticity: elasticityValue / 100,
        iado: iadoValue / 100,
        leadTimeDays: leadTimeDaysValue,
        netSalesValuePerUnit: netSalesPerUnitValue,
        percentageOfVariableCostLostOnObsolescence:
          percentageOfVariableCostLostOnObsolescenceValue / 100,
        replenishmentReviewFrequencyDays: replenishmentReviewFrequencyDaysValue,
        cOV: demandCovValue / 100,
        demandCovValue: demandCovValue / 100,
        forecastErrorCovValue: forecastErrorCovValue / 100,
        shelfLifeDays: shelfLifeDaysValue,
        storageCostPerUnit: storageCostPerUnitValue,
        variableCostPerUnit: variableCostPerUnitValue,
      };

      stockOptimisationApiCall(dataInput);
      setGoButtonPressed(false);
    }
  }, [
    goButtonPressed,
    stockServiceTradeOffChartData.result,
    setStockServiceTradeOffChartData,
    stockOptimisationApiCall,
    availabilityTargets,
    demandValue,
    replenishmentUnitsValue,
    costOfCapitalValue,
    disposalCostPerUnitValue,
    elasticityValue,
    iadoValue,
    leadTimeDaysValue,
    netSalesPerUnitValue,
    percentageOfVariableCostLostOnObsolescenceValue,
    replenishmentReviewFrequencyDaysValue,
    demandCovValue,
    forecastErrorCovValue,
    shelfLifeDaysValue,
    storageCostPerUnitValue,
    variableCostPerUnitValue,
  ]);

  return (
    <Container>

      <Row>
        <Col xs={6} md={12} lg={6}>

          <fieldset className="mb-3">
            <legend>Stock Properties</legend>

            <Form.Group className="mb-2">
              <Form.Label>Average Demand</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Average Demand"
                  type="number"
                  min={0}
                  value={demandValue}
                  onChange={(e): void =>
                    setDemandValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>cases</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Demand COV</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Demand COV"
                  type="number"
                  min={0}
                  value={demandCovValue}
                  onChange={(e): void =>
                    setDemandCovValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Forecast Error COV</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Forecast Error COV"
                  type="number"
                  min={0}
                  value={forecastErrorCovValue}
                  onChange={(e): void =>
                    setForecastErrorCovValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Average Replenishment</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Average Replenishment"
                  type="number"
                  min={0}
                  value={replenishmentUnitsValue}
                  onChange={(e): void =>
                    setReplenishmentUnitsValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>cases</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Lead Time</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Lead Time"
                  type="number"
                  min={0}
                  value={leadTimeDaysValue}
                  onChange={(e): void =>
                    setLeadTimeDaysValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>days</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Replenishment Review Frequency</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Replenishment Review Frequency"
                  type="number"
                  min={0}
                  value={replenishmentReviewFrequencyDaysValue}
                  onChange={(e): void =>
                    setReplenishmentReviewFrequencyDaysValue(
                      parseInt(e.target.value)
                    )
                  }
                />
                <InputGroup.Text>days</InputGroup.Text>
              </InputGroup>
            </Form.Group>

          </fieldset>

          <fieldset className="mb-3">
            <legend>Lost Sales</legend>

            <Form.Group className="mb-2">
              <Form.Label>Net Sales Value Per Unit</Form.Label>
              <InputGroup size="sm">
                <InputGroup.Text>£</InputGroup.Text>
                <Form.Control
                  placeholder="Net Sales Value Per Unit"
                  type="number"
                  min={0}
                  value={netSalesPerUnitValue}
                  onChange={(e): void =>
                    setNetSalesPerUnitValue(parseInt(e.target.value))
                  }
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Elasticity</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Elasticity"
                  type="number"
                  min={0}
                  value={elasticityValue}
                  onChange={(e): void =>
                    setElasticityValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

          </fieldset>

        </Col>
        <Col xs={6} md={12} lg={6}>

        <fieldset className="mb-3">
            <legend>Obsolescence</legend>

            <Form.Group className="mb-2">
              <Form.Label>Shelf Life Days</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Shelf Life Days"
                  type="number"
                  min={0}
                  value={shelfLifeDaysValue}
                  onChange={(e): void =>
                    setShelfLifeDaysValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>days</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Variable Cost Per Unit</Form.Label>
              <InputGroup size="sm">
                <InputGroup.Text>£</InputGroup.Text>
                <Form.Control
                  placeholder="Variable Cost Per Unit"
                  type="number"
                  min={0}
                  value={variableCostPerUnitValue}
                  onChange={(e): void =>
                    setVariableCostPerUnitValue(parseInt(e.target.value))
                  }
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Variable Cost Lost on Obsolescence</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Variable Cost Lost on Obsolescence"
                  type="number"
                  min={0}
                  value={percentageOfVariableCostLostOnObsolescenceValue}
                  onChange={(e): void =>
                    setPercentageOfVaraibleCostLostOnObsolescenceValue(
                      parseInt(e.target.value)
                    )
                  }
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Disposal Cost Per Unit</Form.Label>
              <InputGroup size="sm">
                <InputGroup.Text>£</InputGroup.Text>
                <Form.Control
                  placeholder="Disposal Cost Per Unit"
                  type="number"
                  min={0}
                  value={disposalCostPerUnitValue}
                  onChange={(e): void =>
                    setDisposalCostPerUnitValue(parseInt(e.target.value))
                  }
                />
              </InputGroup>
            </Form.Group>

          </fieldset>

          <fieldset className="mb-3">
            <legend>Warehouse Costs</legend>

            <Form.Group className="mb-2">
              <Form.Label>Cost of Capital</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="Cost of Capital"
                  type="number"
                  min={0}
                  value={costOfCapitalValue}
                  onChange={(e): void =>
                    setCostOfCapitalValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>IADO</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  placeholder="IADO"
                  type="number"
                  min={0}
                  value={iadoValue}
                  onChange={(e): void =>
                    setIADOValue(parseInt(e.target.value))
                  }
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Storage Cost Per Unit</Form.Label>
              <InputGroup size="sm">
                <InputGroup.Text>£</InputGroup.Text>
                <Form.Control
                  placeholder="Storage Cost Per Unit"
                  type="number"
                  min={0}
                  value={storageCostPerUnitValue}
                  onChange={(e): void =>
                    setStorageCostPerUnitValue(parseInt(e.target.value))
                  }
                />
              </InputGroup>
            </Form.Group>

          </fieldset>

        </Col>
      </Row>

      <Button
        variant="primary"
        onClick={() => setGoButtonPressed(!goButtonPressed)}
      >
        Go
      </Button>

    </Container>
  );
}
