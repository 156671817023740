import React from 'react'
import { SKU } from './DemandAndForecastInterfaces'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'

interface SKUCalculationStepsTableProps {
  selectedSKU: SKU | undefined
  data: any
}

export default function SKUCalculationStepsTable ({ selectedSKU, data }: SKUCalculationStepsTableProps) {
  if (!selectedSKU || !data ||
    selectedSKU.demandHistory.length !== data.forecastErrorAnalysis.negativeStrippedDemandHistory.length ||
    selectedSKU.forecastHistory.length !== data.forecastErrorAnalysis.negativeStrippedForecastHistory.length) { return <></> }

  const tableData = [
    {
      title: 'Initial Demand',
      ...(selectedSKU.demandHistory.reduce((accumulator, o) => { return { ...accumulator, [o.date.toISOString()]: o.overriddenDemandQuantity }}, {}))
    },
    {
      title: 'Initial Forecast',
      ...(selectedSKU.forecastHistory.reduce((accumulator, o) => { return { ...accumulator, [o.date.toISOString()]: o.overriddenForecastQuantity }}, {}))
    },
    {
      title: 'Negative & Zero Stripped Demand',
      ...(data.forecastErrorAnalysis.negativeStrippedDemandHistory.reduce((accumulator: any, o: number, i: number) => {
        return { ...accumulator, [selectedSKU.demandHistory[i].date.toISOString()]: o }
      }, {}))
    },
    {
      title: 'Negative & Zero Stripped Forecast',
      ...(data.forecastErrorAnalysis.negativeStrippedForecastHistory.reduce((accumulator: any, o: number, i: number) => {
        return { ...accumulator, [selectedSKU.forecastHistory[i].date.toISOString()]: o }
      }, {}))
    },
    {
      title: 'Forecast Error',
      ...(data.forecastErrorAnalysis.forecastErrors.reduce((accumulator: any, o: number, i: number) => {
        return { ...accumulator, [selectedSKU.demandHistory[i].date.toISOString()]: o }
      }, {}))
    },
    {
      title: 'SdStripped Demand',
      ...(data.sdStrippedDemandAnalysis?.historyData.reduce((accumulator: any, o: number, i: number) => {
        return { ...accumulator, [selectedSKU.demandHistory[i].date.toISOString()]: data.isSdStrippingNecessary ? o : undefined }
      }, {}))
    }
  ]

  const columns = [{
    field: 'title',
    headerName: 'Title',
    flex: 1,
    minWidth: 280,
    type: 'string'
  }].concat(selectedSKU.demandHistory.map(o => { return {
    field: o.date.toISOString(),
    headerName: moment(o.date).format("DD MMM YYYY").toUpperCase(),
    flex: 1,
    minWidth: 100,
    type: 'string'
  }}))

  return (
    <DataGrid
      rows={tableData}
      columns={columns}
      autoHeight
      getRowId={(row) => row.title}
      density='compact'
      disableSelectionOnClick
      hideFooter
    />
  )
}