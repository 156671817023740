import React, { useState } from 'react'
import FVAChart from "../features/FVA/FVA";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useGetForecastErrorAnalysisStepsQuery } from '../services/functions'
import ForecastBiasChart from '../features/ForecastBiasChart/ForecastBiasChart';
import SKUCalculationStepsTable from '../features/DemandAndForecast/SKUCalculationStepsTable';
import StockTargetTable from '../features/DemandAndForecast/StockTargetTable'
import { SKU, ForecastErrorAnalysisStepsReturn } from '../features/DemandAndForecast/DemandAndForecastInterfaces'
import SKUDevUploadPanel from '../features/DemandAndForecast/SKUDevUploadPanel';
import StockTargetPanel from '../features/DemandAndForecast/StockTargetPanel';
import SKUDataTable from '../features/DemandAndForecast/SKUDataTable';
import AlertsDisplay from '../features/AlertsDisplay';
import DemandAndForecastChart from '../features/DemandAndForecast/DemandAndForecastChart';

function DemandAndForecast() {
  const [uploadedData, setUploadedData] = useState<Array<SKU>>([])
  const [selectedSKUIndex, setSelectedSKUIndex] = useState<number>(-1)
  const [selectSalesOrForecast, setSelectSalesOrForecast] = useState("Forecast");

  const [formErrors, setFormErrors] = useState<any>({})

  if (selectedSKUIndex && selectedSKUIndex >= uploadedData.length) {
    setSelectedSKUIndex(-1)
  }

  const selectedSKUObject = selectedSKUIndex >= 0 ? uploadedData[selectedSKUIndex] : undefined
  const forecastAnalysisInput = {
    smoothingPeriods: 1,
    sdSupplyError: selectedSKUObject?.sdSupplyError,
    leadTimeDays: selectedSKUObject?.leadTimeDays,
    replenishmentReviewFrequencyDays: selectedSKUObject?.replenishmentReviewFrequencyDays,
    averageReplenishmentUnits: selectedSKUObject?.averageReplenishmentUnits,
    availabilityTarget: selectedSKUObject?.availabilityTarget,
    rawDemandHistoryData: selectedSKUObject?.demandHistory.map(o => o.demandIsKnockedOut ? null : o.overriddenDemandQuantity),
    rawForecastHistoryData: selectedSKUObject?.forecastHistory.map(o => o.forecastIsKnockedOut ? null : o.overriddenForecastQuantity)
  }
  const hasNoDemand = forecastAnalysisInput.rawDemandHistoryData?.every(o => o === null || o === 0)
  const forecastErrorAnalysisSteps = useGetForecastErrorAnalysisStepsQuery(forecastAnalysisInput, { skip: Object.keys(formErrors).length !== 0 || hasNoDemand }).data as ForecastErrorAnalysisStepsReturn

  const forecastErrorCOV = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.forecastErrorVariability.cov ?? 0) * 100
  const demandVariabilityCOV = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.demandVariability.cov ?? 0) * 100
  const forecastValueAdd = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.forecastValueAdd ?? 0) * 100
  const forecastBias = (forecastErrorAnalysisSteps?.forecastErrorAnalysis.forecastBias ?? 0) * 100

  return (
    <Container>
      <SKUDevUploadPanel uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} setSelectedSKUIndex={setSelectedSKUIndex} selectSalesOrForecast={selectSalesOrForecast} setSelectSalesOrForecast={setSelectSalesOrForecast} />

      <StockTargetPanel uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} stockTargetFormErrors={formErrors} setStockTargetFormErrors={setFormErrors} columns={6} />

      <Card className='mt-2 p-1 pr-0'>
        {hasNoDemand ?
          (
            <Row>
              <Col xs={12} style={{backgroundColor: '#FF0000', color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px', textAlign: 'center'}}>
                Demand totals 0, calculations can not be made.
              </Col>
            </Row>
          ) : (<></>)
        }
        <Row>
          <Col xs={6}>
            <DemandAndForecastChart height={400} width={650} uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} forecastErrorAnalysisSteps={forecastErrorAnalysisSteps} selectSalesOrForecast={selectSalesOrForecast} />
          </Col>
          <Col xs={6}>
            <SKUDataTable uploadedData={uploadedData} setUploadedData={setUploadedData} selectedSKUIndex={selectedSKUIndex} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Col xs={12}>
              Forecast Value Add: {forecastValueAdd.toFixed(1)}%
            </Col>
            <Col>
              <FVAChart lockScale xValue={demandVariabilityCOV} yValue={forecastErrorCOV} />
            </Col>
          </Col>
          <Col xs={3}>
            <Col xs={12}>
              COV of Demand: {demandVariabilityCOV.toFixed(2)}%
            </Col>
            <Col>
              COV of Forecast Error: {forecastErrorCOV.toFixed(2)}%
            </Col>
          </Col>
          <Col xs={6}>
            <Col xs={12}>
              Forecast Bias: {forecastBias.toFixed(1)}%
            </Col>
            <Col>
              <ForecastBiasChart forecastBiasPercentage={forecastBias} height={200} width={600} />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SKUCalculationStepsTable selectedSKU={selectedSKUObject} data={forecastErrorAnalysisSteps} />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col xs={5}>
            <StockTargetTable stockTarget={forecastErrorAnalysisSteps?.stockTarget} showValueRow={false} />
          </Col>
          <Col xs={6}>
            <AlertsDisplay alertProcess={forecastErrorAnalysisSteps?.alertProcess} />
          </Col>
        </Row>
      </Card>
    </Container>
  )
}

export default DemandAndForecast;