import { Button, Card, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { SKU } from './DemandAndForecastInterfaces'
import { downloadToCSV, getPreloadedData } from '../../utils/DemandAndForecastHelpers'

interface SKUPreloadedPanelProps {
  uploadedData: Array<SKU>,
  setUploadedData: Function,
  selectedSKUIndex: number,
  setSelectedSKUIndex: Function
}

function SKUPreloadedPanel({ uploadedData, setUploadedData, selectedSKUIndex, setSelectedSKUIndex}: SKUPreloadedPanelProps) {
  if (!uploadedData || uploadedData.length === 0) {
    setUploadedData(getPreloadedData())
  }

  if (selectedSKUIndex && selectedSKUIndex >= uploadedData.length) {
    setSelectedSKUIndex(-1)
  }

  return (
    <Card>
      <Row className='gy-4 gx-5 p-1'>
        <Col className='d-grid' xs={8}>
          <Form.Select
            value={selectedSKUIndex}
            onChange={e => setSelectedSKUIndex(parseInt(e.target.value as string))}
          >
            <option key={-1} value={-1}>{uploadedData.length > 0 ? 'Select a SKU' : ''}</option>
            {uploadedData.map((o, i) => {
              return (
                <option key={o.skuCode + ' | ' + o.warehouseCode} value={i}>{o.skuName + ' - (' + o.skuCode + ' | ' + o.warehouseCode + ')'}</option>
              )
            })}
          </Form.Select>
        </Col>
        <Col className='d-grid' xs={4}>
          <Button onClick={e => downloadToCSV(uploadedData)}>Download</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default SKUPreloadedPanel;