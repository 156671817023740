import { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import DemandVariabilityOptions from '../features/DemandVariability/DemandVariabilityOptions'
import DemandVariabilityChart from '../features/DemandVariability/DemandVariabliityChart'

function DemandVariability() {
  const [chartData, setChartData] = useState([])
  return (
    <Container>
      <Row>

      <Col lg={{span: 9, order: 'last'}} className='mb-3'>
          <Card className='h-100'>
            <Card.Body>
              <DemandVariabilityChart props={chartData} />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={3} className='mb-3'>
          <Card className='h-100'>
            <Card.Body>
              <DemandVariabilityOptions setChartData={setChartData} />
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Container>
  )
}

export default DemandVariability
