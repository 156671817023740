import * as d3_random from "d3-random"

export const CalculateSeasonaility = (cycleValue: number, arrayLength: number): number[] => {
  const cycleArray: number[] = Array.apply(null, Array(arrayLength)).map((_, i) => (Math.sin(i * 1 * 6.10169491525424 * 0.0174532925199433) * cycleValue))
  return cycleArray
}

export const CalculateNoise = (stdDev: number, arrayLength: number): number[] => {
  const noiseArray: number[] = Array.apply(null, Array(arrayLength)).map(() => d3_random.randomNormal(0, stdDev)())
  return noiseArray
}

export const CalculateTrend = (trendValue: number, demandValue: number, arrayLength: number): number[] => {
  // i / 52 should that not be array length?
  const noiseArray: number[] = Array.apply(null, Array(arrayLength)).map((_, i) => (i / 52 * (trendValue / 100) * demandValue))
  return noiseArray
}

export const CalculatePromo = (promoValue: number, arrayLength: number): number[] => {
  const noiseArray: number[] = Array.apply(null, Array(arrayLength)).map((_, i) => {
    switch (i) {
      case 20:
        return 0.25 * promoValue
      case 21:
        return 0.5 * promoValue
      case 22:
        return 0.75 * promoValue
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
        return 1 * promoValue
      case 28:
        return 0.5 * promoValue
      default:
        return 0
    }
  })
  return noiseArray
}
