export interface Chart {
    id: string
    title: string
    height: number
    width: number
    xAxis: AxisX
    yAxis: AxisY
    series: Series<any>[]
    legend: Legend
}

export interface Axis {
    title: string
}

export interface AxisX extends Axis {
    axisType: AxisType
}

export interface AxisY extends Axis {

}

export enum AxisType {
    Category,
    Numerical,
    Date
}

export class AxisColours {
    grey: string = "#b3b3b3"
}

export interface Legend {
    isVisible: boolean
    position: LegendPosition
}

export enum LegendPosition {
    Top,
    Bottom
}

export interface LineAppearance {
    lineType1: LineType1
    lineType2: LineType2
}

export enum LineType1 {
    Straight,
    Smooth,
    Step_Before,
    Step_At,
    Step_After
}

export enum LineType2 {
    Solid,
    Dash
}

export interface MarkerAppearance {
    markerType: MarkerType
    isVisible: boolean
}

export enum MarkerType {
    Circle,
    Triangle,
    Square,
    Diamond,
    Cross,
    Star,
    Wye
}

export interface Series<T> {
    name: string
    values: SeriesValues<T>[]
    colour: string
    isVisible: boolean
    isVisibleInLegend: boolean
}
export interface SeriesValues<T> {
    name: string
    xValue: any
    yValue: any
    additionalProperties: T
}
export interface UpliftDemoAdditionalProperties {
    dataIndex: number,
    preSdStrippingValue: number,
    isSdStripped: boolean,
    isKnockedOut: boolean,
    showDataPoint: boolean
}
export interface MarkerSeries<T> extends Series<T> {
    markerAppearance: MarkerAppearance
}
export interface BubbleSeries<T> extends Series<T> {
    bubbleRadius: number
}
export interface LineSeries<T> extends MarkerSeries<T> {
    lineAppearance: LineAppearance
    missingValuesBehaviour: MissingValuesBehaviour
}

export class SeriesColours {
    demandHistory: string = "#287e7e"
    forecastHistory: string = "#e9841d"
}

export enum MissingValuesBehaviour {
    Gap,
    Zero,
    Interpolate
}
