import * as d3 from 'd3'
import { useRef, useEffect } from 'react'
import styles from '../../styles/chart.module.scss'
import { nest } from 'd3-collection'

interface ForecastErrorProps {
  label: string
  value: number
}

interface IForecastErrorProps {
  props: ForecastErrorProps[]
}

function ForecastErrorChart({ props }: IForecastErrorProps) {
  const data = props

  const margin: any = { top: 20, right: 10, bottom: 10, left: 10 }
  const width: number = 1000
  const height: number = 300

  const ref: any = useRef()

  const drawChart = (data: ForecastErrorProps[]) => {
    const sumstat = nest()
      .key((d: any) => d.type)
      .entries(data)

    const svg = d3
      .select(ref.current)
      .attr('class', styles.canvas)
      .attr('preserveAspectRatio', 'xMinYMin')
      .attr('viewBox', `-40 -5 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)

    svg
      .selectAll('rect,rect.placeholder-bars,.line-datapoint,line.placeholder-line,#legend,#watermark,circle')
      .remove()

    const selection = svg
      .selectAll('rect')
      .data(sumstat)

    const y: any = d3
      .scaleLinear()
      .domain([-1000, 1000])
      .range([height, 0])

    const x: any = d3
      .scaleBand()
      .domain(data.map(o => o.label))
      .range([0, (width - (margin.left + margin.right))])

    selection
      .enter()
      .append('g')
      .attr('class', 'grid')

    selection
      .enter()
      .append('g')
      .attr('id', 'yAxis')
      .attr('color', '#b3b3b3')
      .call(y)

    const yAXIS: any = d3
      .axisLeft(y)
      .ticks(5)

    svg
      .select('#yAxis')
      .attr('class', styles.yaxis)
      .transition()
      .call(yAXIS)

    svg
      .selectAll('.lineTest')
      .remove()

    let line: any = svg
      .selectAll('.lineTest')
      .data([data], function (d: any) { return d.label })

    line = line
      .data(sumstat)
      .enter()
      .append('path')
      .attr('class', 'lineTest')
      .merge(line)

    const valueline = (d: any) => d3
      .line()
      .x((d: any) => x(d.label))
      .y((d: any) => y(d.value))
      (d.values)

    line
      .attr('d', (d: any) => valueline(d))
      .attr('id', function (d: any) { return d.key + '-line' })
      .attr('stroke', '#C00000')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')

    const legendHolder = svg
      .append('g')
      .attr('transform', 'translate(' + (-width) + ',' + (-margin.top) + ')')

    const legend = legendHolder
      .selectAll('.legendHolder')
      .data(sumstat.slice())
      .enter()
      .append('g')
      .attr('width', 36)

    legend
      .append('rect')
      .attr('x', (d: any, i: number) => (width + (150 * i)))
      .attr('y', height + margin.bottom + margin.top)
      .attr('width', 15)
      .attr('height', 3)
      .attr('fill', '#C00000')

    legend
      .append('text')
      .attr('x', (d, i) => (width + (150 * i) + 20))
      .attr('y', height + margin.bottom + margin.top)
      .attr('dy', '.5em')
      .text(d => 'Forecast Error (Demand - Forecast)')
      .attr('fill', '#333333')
      .attr('id', 'legend')
      .attr('class', styles.legend)

    selection
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'baseline')
      .style('fill', d => '#C00000')
      .attr('x', function (d) {
        return x(d.label)
      })
      .attr('width', '1.5')
      .attr('y', function (d) {
        return d.value > 0 ? y(d.value) : y(0)
      })
      .attr('height', function (d) {
        return (y(d.value) - y(0)) < 0
          ? y(0) - y(d.value)
          : (y(d.value) - y(0))
      })
  }

  useEffect(() => {
    if (ref.current && ref.current.clientWidth) {
      drawChart(data)
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <svg ref={ref} />
  )
}

export default ForecastErrorChart
