import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"


export default function useFetchAccessToken() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0()
    const [accessToken, setAccessToken] = useState<string | null>(null)
    useEffect(() => {
        const getAccessToken = async () => {
            try {
                setAccessToken(await getAccessTokenSilently())
            } catch (error: any) {
                console.error(error.message)
            }
        }

        if (isAuthenticated) {
            getAccessToken()
        }

    }, [getAccessTokenSilently, isAuthenticated])

    return accessToken
}