import { Button, Card, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { SKU } from './DemandAndForecastInterfaces'
import { uploadCSV } from '../../utils/DemandAndForecastHelpers'
import { downloadToCSV } from '../../utils/DemandAndForecastHelpers'

interface SKUUploadPanelProps {
  uploadedData: Array<SKU>,
  setUploadedData: Function,
  selectedSKUIndex: number,
  setSelectedSKUIndex: Function,
}

function SKUUploadPanel({ uploadedData, setUploadedData, selectedSKUIndex, setSelectedSKUIndex }: SKUUploadPanelProps) {
  if (selectedSKUIndex && selectedSKUIndex >= uploadedData.length) {
    setSelectedSKUIndex(-1)
  }

  return (
    <Card>
      <Row className='gy-4 gx-5 p-1'>
        <Col className='d-grid' xs={4}>
          <input
            className='csv-input'
            type='file'
            name='file'
            placeholder={undefined}
            onChange={e => uploadCSV(e, setUploadedData)}
          />
        </Col>
        <Col className='d-grid' xs={6}>
          <Form.Select
            value={selectedSKUIndex}
            onChange={e => setSelectedSKUIndex(parseInt(e.target.value as string))}
          >
            <option key={-1} value={-1}>{uploadedData.length > 0 ? 'Select a SKU' : ''}</option>
            {uploadedData.map((o, i) => {
              return (
                <option key={o.skuCode + ' | ' + o.warehouseCode} value={i}>{o.skuName + ' - (' + o.skuCode + ' | ' + o.warehouseCode + ')'}</option>
              )
            })}
          </Form.Select>
        </Col>
        <Col className='d-grid' xs={2}>
          <Button onClick={e => downloadToCSV(uploadedData)}>Download</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default SKUUploadPanel;
