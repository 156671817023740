import FVAChart from "../features/FVA/FVA";
import ForecastErrorChart from "../features/ForecastError";
import UpliftDemoChart from "../features/UpliftDemo/UpliftDemo";
import ForecastErrorOptions from "../features/ForecastError/ForecastErrorOptions";
import { useState } from "react";
import ForecastErrorCOVChart from "../features/ForecastError/ForecastErrorCOVChart";
import ForecastErrorCOVOptions from "../features/ForecastError/ForecastErrorCOVOptions";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Series, SeriesValues, UpliftDemoAdditionalProperties } from "../utils/ChartConfigurations";

interface apiData {
  demandVariability: {
    sDs: number;
    cov: number;
  };
  forecastErrorVariability: {
    sDs: number;
    cov: number;
  };
  forecastValueAdd: number;
}

function ForecastError() {
  //custom hook
  const [forecastChartData, setForecastChartData] = useState([]);
  const [upliftDemoChartData, setUpliftDemoChartData] = useState([]);
  const [selectSalesOrForecast, setSelectSalesOrForecast] =
    useState("Forecast");

  const [perfectForecastValues, setPerfectForecastValues] = useState<Series<UpliftDemoAdditionalProperties>>();
  const [salesValues, setSalesValues] = useState<Series<UpliftDemoAdditionalProperties>>();
  const [apiData, setApiData] = useState<apiData>();

  function setForecastValuesFromChart(newValues: SeriesValues<UpliftDemoAdditionalProperties>[]) {
    if(perfectForecastValues) {
      setPerfectForecastValues({ ...perfectForecastValues, values: newValues })
    }
  }
  function setSalesValuesFromChart(newValues: SeriesValues<UpliftDemoAdditionalProperties>[]) {
    if(salesValues) {
      setSalesValues({ ...salesValues, values: newValues })
    }
  }

  const forecastErrorCOV = apiData?.forecastErrorVariability.cov
    ? apiData.forecastErrorVariability.cov * 100
    : 0;
  const demandVariabilityCOV = apiData?.demandVariability.cov
    ? apiData.demandVariability.cov * 100
    : 0;
  const forecastValueAdd = apiData?.forecastValueAdd
    ? apiData.forecastValueAdd * 100
    : 0;

  const [feCOVData, setFeCOVData] = useState([]);
  const [dvCOVData, setDvCOVData] = useState([]);
  const [overlayForecastError, setOverlayForecastError] = useState(false);
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          md={7}
          lg={8}
          xl={9}
        >
          <Card
            className="mb-3"
          >
            <Card.Body>
              <ForecastErrorOptions
                setSalesValues={setSalesValues}
                setPerfectForecastValues={setPerfectForecastValues}
                salesValues={salesValues}
                perfectForecastValues={perfectForecastValues}
                setForecastChartData={setForecastChartData}
                setUpliftDemoChartData={setUpliftDemoChartData}
                setApiData={setApiData}
                setSelectSalesOrForecast={setSelectSalesOrForecast}
                selectSalesOrForecast={selectSalesOrForecast}
                setFeCOVData={setFeCOVData}
                setDvCOVData={setDvCOVData}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col
          xs={12}
          md={5}
          lg={4}
          xl={3}
        >
          <Card
            className="mb-3"
          >
            <Card.Body>

              <Container>
                <Col xs={12}>
                  Forecast Value Add: {forecastValueAdd.toFixed(1)}%
                </Col>
                <Col>
                  <FVAChart
                    lockScale
                    xValue={demandVariabilityCOV}
                    yValue={forecastErrorCOV}
                  />
                </Col>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>

        <Col xs={12}>
          <Card
            className="mb-3"
          >
            <Card.Body>
              <Container>
                <Row>

                  <Col xs={9}>
                    <UpliftDemoChart
                      props={upliftDemoChartData}
                      width={1000}
                      height={450}
                      margin={{ top: 20, right: 10, bottom: 10, left: 10 }}
                      selectSalesOrForecast={selectSalesOrForecast}
                      setPerfectForecastValues={setForecastValuesFromChart}
                      setSalesValues={setSalesValuesFromChart}
                      showSdStrippingThreshold={false}
                      sdStrippingThresholds={[]}
                    />
                  </Col>
                  <Col xs={3} className='d-flex align-items-end flex-row'>
                    <div className="svg-container">
                      <ForecastErrorCOVChart
                        forecastErrorData={feCOVData}
                        demandData={dvCOVData}
                        overlayForecastError={overlayForecastError}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} />
                  <Col xs={3}>
                    Demand COV: {demandVariabilityCOV.toFixed(1)}%
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>

        <Col xs={12}>
          <Card
            className="mb-3"
          >
            <Card.Body>
              <Container>
                <Row>
                  <Col xs={9}>
                    <ForecastErrorChart props={forecastChartData} />
                  </Col>
                  <Col xs={3} className='d-flex align-items-end flex-row'>
                    <div className="svg-container">
                      <ForecastErrorCOVChart
                        forecastErrorData={feCOVData}
                        demandData={[]}
                        overlayForecastError={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col xs={9} />
                  <Col xs={3}>
                    <ForecastErrorCOVOptions
                      overlayForecastError={overlayForecastError}
                      setOverlayForecastError={setOverlayForecastError}
                    />
                    Forecast Error COV: {forecastErrorCOV.toFixed(1)}%
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container >
  );
}

export default ForecastError;
