import { AlertProcess } from "../utils/Interfaces"
import moment from 'moment'

interface AlertsDisplayProps {
  alertProcess: AlertProcess
}

export default function AlertsDisplay ({ alertProcess }: AlertsDisplayProps) {
  return (
    alertProcess !== null && alertProcess !== undefined ?
      <div>
        <p>Process ran from '{moment(new Date(alertProcess.start)).format("DD MMM YYYY hh:mm:ss").toUpperCase()}' to '{moment(new Date(alertProcess.end)).format("DD MMM YYYY hh:mm:ss").toUpperCase()}'</p>
        {alertProcess.alerts.map((o: any) => { return <p>{GetSeverityText(o.severity)}: {o.messageText}</p> })}
      </div>
      : <></>
  )
}

function GetSeverityText(severityNum: number) {
  switch (severityNum) {
    case 0: return 'INFORMATION'
    case 1: return 'WARNING'
    case 2: return 'ERROR'
    default: return 'UNKNOWN'
  }
}
